import classes from './PageNotFound.module.css'
import logo from '../../images/Logo light background.svg'

const PageNotFound = () => {

    return <div className={classes.background}>
        <div className={classes.logo}><img alt='' src={logo}/></div>
        <div className={classes['message-box']}>
            <p className={classes.error}>Error 404</p>
            <h2 className={classes.header}>Page Not Found</h2>
            <p className={classes.message}>The page you are looking for doesn't exist<br/> or an other error occured.</p>
        </div>
    </div>
} 
export default PageNotFound