import React, { useState, Fragment } from 'react'
import { NavLink, useParams } from 'react-router-dom'

import statement from '../../../images/Nav-Statement.svg'
import statementActive from '../../../images/Nav-Statement active.svg'
import students from '../../../images/Nav-Students.svg'
import studentsActive from '../../../images/Nav-Students active.svg'
import registration from '../../../images/Nav-Registration.svg'
import registrationActive from '../../../images/Nav-Registration active.svg'
import classes from './MainSidebar.module.css'

const SidebarNavParents = (props) => {
    
    const params = useParams()

    const [statementSrc, setStatementSrc] = useState(statement)
    const [studentsSrc, setStudentsSrc] = useState(students)
    const [registrationSrc, setRegistrationSrc] = useState(registration)

    const statementHoverHandler = () => {
        setStatementSrc(statementActive)
    }

    const statementLeaveHandler = () => {
        setStatementSrc(statement)
    }

    const studentsHoverHandler = () => {
        setStudentsSrc(studentsActive)
    }

    const studentsLeaveHandler = () => {
        setStudentsSrc(students)
    }

    const registrationHoverHandler = () => {
        setRegistrationSrc(registrationActive)
    }

    const  registrationLeaveHandler = () => {
        setRegistrationSrc(registration)
    }



    const activeStatement = <Fragment><div><img src={statementActive} alt='' /></div><label>Statement</label></Fragment>
    const inActiveStatement = <Fragment><div><img src={statementSrc} alt='' /></div><label>Statement</label></Fragment>

    const activeStudents = <Fragment><div><img src={studentsActive} alt='' /></div><label>Students</label></Fragment>
    const inActiveStudents = <Fragment><div><img src={studentsSrc} alt='' /></div><label>Students</label></Fragment>

    const activeRegistration = <Fragment><div><img src={registrationActive} alt='' /></div><label>Registration</label></Fragment>
    const inActiveRegistration = <Fragment><div><img src={registrationSrc} alt='' /></div><label>Registration</label></Fragment>

    return (
        <Fragment>
                <li className={classes['main-sidebar__item']} onMouseOver={statementHoverHandler} onMouseOut={statementLeaveHandler} onClick={() => props.onClose ? props.onClose() : {}}>
                    <NavLink to={`/${params.organization}/${params.school}/parents/statement`}
                        className={(navData) => `${classes['main-sidebar__nav']} ${navData.isActive ? classes.active : ''}`}
                        children={(navData) => navData.isActive ? activeStatement : inActiveStatement}
                    />
                </li>
                <li className={classes['main-sidebar__item']} onMouseOver={studentsHoverHandler} onMouseOut={studentsLeaveHandler} onClick={() => props.onClose ? props.onClose() : {}}>
                    <NavLink to={`/${params.organization}/${params.school}/parents/students`}
                        className={(navData) => `${classes['main-sidebar__nav']} ${navData.isActive ? classes.active : ''}`}
                        children={(navData) => navData.isActive ? activeStudents : inActiveStudents}
                    />
                </li>
                {localStorage.getItem('ShowRegistration') && localStorage.getItem('ShowRegistration') === 'true' && <li className={classes['main-sidebar__item']} onMouseOver={registrationHoverHandler} onMouseOut={registrationLeaveHandler} onClick={() => props.onClose ? props.onClose() : {}}>
                    <NavLink to={`/${params.organization}/${params.school}/parents/registration`}
                        className={(navData) => `${classes['main-sidebar__nav']} ${navData.isActive ? classes.active : ''}`}
                        children={(navData) => navData.isActive ? activeRegistration : inActiveRegistration}
                    />
                </li>}
        </Fragment>
    )
}

export default SidebarNavParents