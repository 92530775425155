export const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    result = result.toLocaleString("sv-SE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    })
    return result;
  }

export const addWeeks = (date, weeks) => {
    return addDays(date, weeks * 7)
}

export const addMonths = (date, months) => {
    var result = new Date(date)
    var d = result.getDate();
    result.setMonth(result.getMonth() + +months);
    if (result.getDate() !== d) {
        result.setDate(0);
    }
    result.setDate(result.getDate() + 1);
    result = result.toLocaleString("sv-SE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    })

    return result;
}

export const addYears = (date, years) => {
    var result = new Date(date)
    var d = result.getDate();
    result.setFullYear(result.getFullYear() + +years);
    if (result.getDate() !== d) {
        result.setDate(0);
    }
    result.setDate(result.getDate() + 1);
    result = result.toLocaleString("sv-SE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    })

    return result;
}

export const daysDiff = (first, second) => { 
    var firstDate = new Date(first)
    var secondDate = new Date(second)       
    return Math.round((secondDate - firstDate) / (1000 * 60 * 60 * 24));
}

export const weeksDiff = (first, second) => {
    var firstDate = new Date(first)
    var secondDate = new Date(second)       
    return Math.floor((secondDate - firstDate) / (7 * 24 * 60 * 60 * 1000));
}

export const monthsDiff = (first, second) => {
    var firstDate = new Date(first)
    var secondDate = new Date(second)       
    var months;
    months = (secondDate.getFullYear() - firstDate.getFullYear()) * 12;
    months -= firstDate.getMonth();
    months += secondDate.getMonth();
    return months <= 0 ? 0 : months;
}

export const YearsDiff = (first, second) => {
    var ageDifMs = new Date(second) - new Date(first);
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const formatDateObjectForInput = (date) => {
    var result = new Date(date)
    result = result.toLocaleString("sv-SE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    })
    return result;
}