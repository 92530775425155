import { Fragment, useEffect, useState } from "react"

import classes from './TaskListForm.module.css'
import AddButton from "../../../UI/Buttons/AddButtons/AddButton"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { fetchAllDashboardTasksAndTotals, fetchDashboards, fetchSMSTemplates } from "../../../../store/hub-actions"
import LoadingSpinnerMiddle from "../../../UI/LoadingSpinners/LoadingSpinnerMiddle"
import Dashboard from "./Dashboard"
import { formatDateWithDayName } from "../../../../functions/Dates/formatDate"

const TaskListForm = () => {
    const [date, setDate] = useState(formatDateWithDayName(new Date()))

    const dashboardsLoading = useSelector((state) => state.hub.dashboardsLoading)
    const dashboards = useSelector((state) => state.hub.dashboards)
    const loadedAll = useSelector((state) => state.hub.loadedAllDashboards)


    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(fetchDashboards())
        dispatch(fetchAllDashboardTasksAndTotals())
    },[])

    const addTaskHandler = () => {
        navigate('new-task')
    }

   
    return (
        <Fragment>
        <section className={classes['tasks-page']}>
            <div className={classes['header-line']}>
                <h2 className={classes.header}>{date}</h2>
                <AddButton onClick={addTaskHandler}>Add Task</AddButton>
            </div>
            {dashboards.length > 0 && dashboards.map(d => <Dashboard dashboard={d} key={d.DashboardSettingID}/>)}
        </section>
        {dashboardsLoading && <div className={classes['spinner-wrapper']}><LoadingSpinnerMiddle/></div>}
        </Fragment>
    )
}

export default TaskListForm