
import Plus from '../../../../images/Plus-light small.svg'
import ButtonM from '../Buttons/ButtonM'
import classes from './AddButton.module.css'


const AddButton = (props) => {
    return (
        <ButtonM onClick={props.onClick}>
            <img src={Plus} alt='' className={classes.plus}/>
            {props.children}
        </ButtonM>       
    )
}

export default AddButton