import { Fragment, useEffect, useState } from 'react'

import classes from './MorePopup.module.css'
import arrow from '../../../../images/Arrow - Right 2.svg'
import { addDays } from '../../../../functions/Dates/dateFunctions'
import useHttp from '../../../../hooks/use-http'
import { converDateForBackendWithCurrentTime } from '../../../../functions/Dates/ConvertDateForC#'
import DateInput from '../../../UI/Inputs/DateInput'
import { useDispatch } from 'react-redux'
import { uiActions } from '../../../../store/ui-slice'
import { fetchAllDashboardTasksAndTotals, fetchDashboards } from '../../../../store/hub-actions'
import { fetchDonorsAddressesList, fetchDonorsContactsList, fetchDonorsDetails } from '../../../../store/donors-actions'
import { fetchAllTendersLists } from '../../../../store/tenders-actions'
import { fetchPledgesForDonor, fetchRecurringPledgesForDonor } from '../../../../store/pledges-actions'
import { useNavigate } from 'react-router'
import ChargeTypes from './ChargeTypes'

const MorePopup = (props) => {
    const navigate = useNavigate()

    const [showSnooze, setShowSnooze] = useState()
    const [showCustomSnooze, setShowCustomSnooze] = useState()
    const [customDueDate, setCustomDueDate] = useState()
    const [moreActions, setMoreActions] = useState([])
    const [showChargeTypes, setShowChargeTypes] = useState()

    const {sendRequest: snoozeTask, isLoading: snoozeTaskLoading} = useHttp()
    const {sendRequest: noResponse, isLoading: noResponseLoading} = useHttp()
    const {sendRequest: completeTask, isLoading: completeTaskLoading} = useHttp()

    useEffect(()=>{
        if(props.actions){
            let actions = props.actions.split(',')
            if(actions){
                let a = actions.map((action, i) => {
                    let actionDisplayName = action.replace(/([A-Z])/g, ' $1').trim()
                    if(props.workWith && ['Parents', 'Students'].includes(props.workWith)){
                        if(action.trim() === 'AddPledge'){
                            actionDisplayName = 'Add School Charge'
                        }
                        else if(action.trim() === 'AddRecurringPledge'){
                            actionDisplayName = 'Add School Recurring Charge'
                        }
                        else if(action.trim() === 'AddPayment'){
                            actionDisplayName = 'Add School Payment'
                        }
                        else if(action.trim() === 'AddRecurringPayment'){
                            actionDisplayName = 'Add School Recurring Payment'
                        }
                    }
                    return <div key={i} className={classes['popup-item']} onClick={() => actionHandler(action.trim())} id={`task-action ${props.dashboardSettingId} ${action.trim()} ${props.task.ID}`}>
                    {actionDisplayName}
                    {action.trim() === 'AddPledge' && showChargeTypes && showChargeTypes === 'charge' && <ChargeTypes dashboardId={props.dashboardSettingId} taskId={props.task.ID} onClose={props.onClose} details={props.details}/>}
                    {action.trim() === 'AddRecurringPledge' && showChargeTypes && showChargeTypes === 'recCharge' && <ChargeTypes recurring dashboardId={props.dashboardSettingId} taskId={props.task.ID} onClose={props.onClose} details={props.details}/>}
                    </div>
                })
                setMoreActions(a)
            }
        }
        else if(props.details){
            setMoreActions([<p className={classes['no-actions']}>No actions set up</p>])
        }
    }, [props.actions, props.task.ID, showChargeTypes])
    const dispatch = useDispatch()

    const snoozeHandler = (days) => {
        if(!snoozeTaskLoading){
            let date = new Date()
            date = addDays(date, days)
            snooze(date)
        }
    }

    const snooze = (date) => {
            date = converDateForBackendWithCurrentTime(date)
            snoozeTask({url: `api/snoozeReminder?dashboardSettingId=${props.dashboardSettingId}&id=${props.task.ID}&reminderId=${props.task.ReminderID}&date=${date}`}, handleSnoozeResponse)
    }

    const toggleShowCustomSnoozehandler = () => {
        setShowCustomSnooze(prevState =>  !prevState)
    }

    const handleSnoozeResponse = (data) => {
        if(data === ''){
            dispatch(uiActions.showSidePopup({status: 'success', header: 'Successfuly snoozed task'}))
            dispatch(fetchAllDashboardTasksAndTotals())
        }
        props.onClose()

    }

    const noResponseHandler = () => {
        if(!noResponseLoading){
            noResponse({url: `api/reminderNoResponse?dashboardSettingId=${props.dashboardSettingId}&id=${props.task.ID}&reminderId=${props.task.ReminderID}`}, handleNoResponseResponse)
        }
    }

    const handleNoResponseResponse = (data) => {
        if(data === ''){
            dispatch(uiActions.showSidePopup({status: 'success', header: 'Successfuly updated task'}))
            dispatch(fetchAllDashboardTasksAndTotals())
        }
        props.onClose()
    }

    const completeHandler = () => {
        if(!completeTaskLoading){
            completeTask({url: `api/completeTask?dashboardSettingId=${props.dashboardSettingId}&id=${props.task.ID}&reminderId=${props.task.ReminderID}`}, handleCompleteTaskResponse)
        }
    }

    const handleCompleteTaskResponse = (data) => {
        if(data === ''){
            dispatch(uiActions.showSidePopup({status: 'success', header: 'Successfuly completed task'}))
            dispatch(fetchAllDashboardTasksAndTotals())
        }
    }
  
    const actionHandler = (action) => {
        if(window.location.href.indexOf("-app") > -1){
            if(props.workWith && ['Parents', 'Students'].includes(props.workWith)){
                switch(action){
                    case 'AddPledge':
                        setShowChargeTypes('charge')
                        return;
                    case 'AddRecurringPledge':
                        setShowChargeTypes('recCharge')
                        return;
                    default:
                        props.onClose()
                        return;
                    }
                }
                else{
                    props.onClose()
                    return;
                }
            }
        props.onClose()
        //only if working with donorid it should get all this
        if(props.workWith === 'Donors'){
            dispatch(fetchDonorsDetails(props.task.DonorID))
            dispatch(fetchDonorsAddressesList(props.task.DonorID))
            dispatch(fetchDonorsContactsList(props.task.DonorID))
            dispatch(fetchAllTendersLists(props.task.DonorID))
            dispatch(fetchPledgesForDonor(props.task.DonorID))
            dispatch(fetchRecurringPledgesForDonor(props.task.DonorID))
            switch(action){
                case 'AddPayment':
                    navigate(`/paymentslist/new-payment?donorId=${props.task.DonorID}&hub=1`)
                    break;
                case 'AddPledge':
                    navigate(`/pledges-list/new-pledge?donorId=${props.task.DonorID}&hub=1`)
                    break;
                case 'AddRecurringPayment':
                    navigate(`/rec-payments-list/new-payment?donorId=${props.task.DonorID}&hub=1`)
                    break;
                case 'AddRecurringPledge':
                    navigate(`/rec-pledges-list/new-pledge?donorId=${props.task.DonorID}&hub=1`)
                    break;
                default:
                    break;
            }
    
        }
    }
    return (
        <div className={`${classes.popup} ${(snoozeTaskLoading || noResponseLoading || completeTaskLoading) && classes.loading}`}>
            {!props.details &&<Fragment> <p className={classes['popup-item']} onClick={() => {props.onOpenDetails(); props.onClose();}}>Notes and details</p>
           <div className={classes['popup-item']} onClick={() => setShowSnooze(true)}>Snooze <img src={arrow} alt='' />
            {showSnooze && <div className={classes['snooze-popup']}>
                <p className={classes['popup-item']} onClick={() => snoozeHandler(1)}>24 hours</p>
                <p className={classes['popup-item']} onClick={() => snoozeHandler(2)}>2 days</p>
                <p className={classes['popup-item']} onClick={() => snoozeHandler(7)}>1 week</p>
                <p className={classes['popup-item']} onClick={toggleShowCustomSnoozehandler}>custom</p>
                {showCustomSnooze && <DateInput label='Due date' value={customDueDate} onChange={(v) => snooze(v)} className={classes['custom-date']}/>}
            </div>}</div>
            <p className={classes['popup-item']} onClick={noResponseHandler}>No Response</p>
            <p className={classes['popup-item']} onClick={completeHandler}>Complete</p>
            </Fragment>}
            {moreActions}
        </div>
    )
}

export default MorePopup