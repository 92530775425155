import { createSlice } from "@reduxjs/toolkit";

let logoutTimer;

const calculateRemainingTime = (expirationTime) => {
    const currentTime = new Date().getTime();
    const adjExpirationTime = new Date(expirationTime).getTime();

    const remainingDuration = adjExpirationTime - currentTime;

    return remainingDuration;
};


const authSlice = createSlice({
    name: "auth",
    initialState: {
        token: localStorage.getItem('token') && localStorage.getItem('expirationTime') && calculateRemainingTime(localStorage.getItem('expirationTime')) > 3600 ? localStorage.getItem('token') : '',
        isLoggedIn: localStorage.getItem('token') && localStorage.getItem('expirationTime') && calculateRemainingTime(localStorage.getItem('expirationTime')) > 3600 && localStorage.getItem('role') && localStorage.getItem('role') === 'user' ? true : false,
        portalUserLoggedIn: localStorage.getItem('token') && localStorage.getItem('expirationTime') && calculateRemainingTime(localStorage.getItem('expirationTime')) > 3600 && localStorage.getItem('role') && localStorage.getItem('role') === 'portalUser' ? true : false,
        hubUserLoggedIn: localStorage.getItem('token') && localStorage.getItem('expirationTime') && calculateRemainingTime(localStorage.getItem('expirationTime')) > 3600 && localStorage.getItem('role') && localStorage.getItem('role') === 'hubUser' ? true : false,
        parentLoggedIn: localStorage.getItem('token') && localStorage.getItem('expirationTime') && calculateRemainingTime(localStorage.getItem('expirationTime')) > 3600 && localStorage.getItem('role') && localStorage.getItem('role') === 'parent' ? true : false,
        userName: localStorage.getItem('userName') ? localStorage.getItem('userName') : '',
        addPermission: localStorage.getItem('addPermission') ? localStorage.getItem('addPermission') : '',
        updatePermission: localStorage.getItem('updatePermission') ? localStorage.getItem('updatePermission') : '',
        deletePermission: localStorage.getItem('deletePermission') ? localStorage.getItem('deletePermission') : '',
        hub: localStorage.getItem('hub') && localStorage.getItem('hub') === 'true' ? true : false,
        allowChargeCreditCard: localStorage.getItem('allowChargeCreditCard') ? localStorage.getItem('allowChargeCreditCard') : '',
        url:process.env.REACT_APP_BACKEND_URL,  
    },
    reducers: {
        login(state, action) {
            if (action.payload.token && action.payload.duration && action.payload.userName) {
                const expirationTime = new Date(
                    new Date().getTime() + +action.payload.duration * 1000
                );
                var addP = (action.payload.addPermission.toLowerCase() === 'true') 
                var updateP = (action.payload.updatePermission.toLowerCase() === 'true')
                var deleteP = (action.payload.deletePermission.toLowerCase() === 'true')
                var hub = (action.payload.hub.toLowerCase() === 'true')
                var allowCredirCard = (action.payload.allowChargeCreditCard.toLowerCase() === 'true')
                localStorage.setItem('token', action.payload.token)
                localStorage.setItem('expirationTime', expirationTime.toISOString())
                localStorage.setItem('addPermission', addP)
                localStorage.setItem('updatePermission', updateP)
                localStorage.setItem('deletePermission', deleteP)
                localStorage.setItem('hub', hub)
                localStorage.setItem('allowChargeCreditCard', allowCredirCard)
                localStorage.setItem('sessionUserName', action.payload.userName)
                localStorage.setItem('sessionCompanyName', action.payload.companyName)
                localStorage.setItem('inTrial', action.payload.inTrial)
                localStorage.setItem('trialEndDate', action.payload.trialEndDate)
                localStorage.setItem('role', action.payload.role)
                localStorage.setItem('hasJiraCredentials', action.payload.hasJiraCredentials)
                state.isLoggedIn = action.payload.role === 'user'?  true : false
                state.parentLoggedIn = false
                state.portalUserLoggedIn = false
                state.hubUserLoggedIn = action.payload.role === 'hubUser'? true : false
                state.token = action.payload.token
                state.userName = action.payload.userName
                state.addPermission = addP
                state.updatePermission = updateP
                state.deletePermission = deleteP
                state.hub = hub
                state.allowChargeCreditCard = allowCredirCard
                const remainingTime = calculateRemainingTime(expirationTime);


                logoutTimer = setTimeout(() => {
                    state.isLoggedIn = false;
                    state.parentLoggedIn = false;
                    state.portalUserLoggedIn = false;
                    state.hubUserLoggedIn = false;
                    state.token = '';
                    state.userName = ''
                    localStorage.removeItem('token')
                    localStorage.removeItem('expirationTime');
                    localStorage.removeItem('addPermission')
                    localStorage.removeItem('updatePermission')
                    localStorage.removeItem('deletePermission')
                    localStorage.removeItem('hub')
                    localStorage.removeItem('allowChargeCreditCard')
                    localStorage.removeItem('sessionUserName')
                    localStorage.removeItem('sessionCompanyName')
                    localStorage.removeItem('inTrial')
                    localStorage.removeItem('trialEndDate')
                    localStorage.removeItem('role')
                    localStorage.removeItem('hasJiraCredentials')
                }

                    , remainingTime);
            }
        },
        portalUserLogin(state, action){
                if (action.payload.token && action.payload.duration && action.payload.userName) {
                    const expirationTime = new Date(
                        new Date().getTime() + +action.payload.duration * 1000
                    );
                    localStorage.setItem('token', action.payload.token)
                    localStorage.setItem('expirationTime', expirationTime.toISOString())
                    localStorage.setItem('sessionUserName', action.payload.userName)
                    localStorage.setItem('sessionCompanyName', action.payload.companyName)
                    localStorage.setItem('role', 'portalUser')
                    localStorage.setItem('hasJiraCredentials', action.payload.hasJiraCredentials)
                    state.portalUserLoggedIn = true;
                    state.isLoggedIn = false
                    state.hubUserLoggedIn = false;
                    state.parentLoggedIn = false
                    state.token = action.payload.token
                    state.userName = action.payload.userName
    
                    const remainingTime = calculateRemainingTime(expirationTime);
    
                    logoutTimer = setTimeout(() => {
                        state.portalUserLoggedIn = false;
                        state.token = '';
                        state.userName = ''
                        localStorage.removeItem('token')
                        localStorage.removeItem('expirationTime');
                        localStorage.removeItem('sessionUserName')
                        localStorage.removeItem('sessionCompanyName')
                        localStorage.removeItem('role')
                        localStorage.removeItem('hasJiraCredentials')
                    }
    
                        , remainingTime);
                }    
        },
        parentLogin(state, action){
            if (action.payload.token && action.payload.duration && action.payload.userName) {
                const expirationTime = new Date(
                    new Date().getTime() + +action.payload.duration * 1000
                    );
                    localStorage.setItem('token', action.payload.token)
                    localStorage.setItem('expirationTime', expirationTime.toISOString())
                    localStorage.setItem('sessionUserName', action.payload.userName)
                    localStorage.setItem('sessionCompanyName', action.payload.companyName)
                    localStorage.setItem('role', 'parent')
                    state.parentLoggedIn = true
                    state.isLoggedIn = false
                    state.portalUserLoggedIn = false
                    state.hubUserLoggedIn = false;
                    state.token = action.payload.token
                    state.userName = action.payload.userName
                    const remainingTime = calculateRemainingTime(expirationTime);
                    logoutTimer = setTimeout(() => {
                        state.parentLoggedIn = false;
                        state.token = '';
                        state.userName = ''
                        localStorage.removeItem('token')
                        localStorage.removeItem('expirationTime');
                        localStorage.removeItem('sessionUserName')
                        localStorage.removeItem('sessionCompanyName')
                        localStorage.removeItem('role')
                    }
                    , remainingTime);
                }
                
            },
            logout(state) {
                state.isLoggedIn = false;
                state.parentLoggedIn = false;
                state.portalUserLoggedIn = false;
                state.token = '';
                state.userName = ''
                localStorage.removeItem('token')
                localStorage.removeItem('expirationTime');
                localStorage.removeItem('addPermission')
                localStorage.removeItem('updatePermission')
                localStorage.removeItem('deletePermission')
                localStorage.removeItem('hub')
                localStorage.removeItem('allowChargeCreditCard')
                localStorage.removeItem('sessionUserName')
                localStorage.removeItem('sessionCompanyName')
                localStorage.removeItem('inTrial')
                localStorage.removeItem('trialEndDate')
                localStorage.removeItem('role')
                localStorage.removeItem('hasJiraCredentials')
        },
    },
},
);

export const authActions = authSlice.actions;

export default authSlice;
