import classes from './CircleProgressBar.module.css'

const CircleProgressBar = (props) => {
    return(
<div className={classes["wrap-circles"]}>

<div className={`${classes.circle} `} style={{ backgroundImage: `conic-gradient(#70921A ${props.percent}%, #D9DDE3 0)`}}>
  <div className={classes["inner"]}></div>
</div>

</div>)}

export default CircleProgressBar