import { Outlet } from "react-router-dom"
import TaskListForm from "../../components/Hub/Tasks/TasksList/TaskListForm"
import Layout from "../../components/layout/Layout"
import MainHeader from "../../components/layout/MainHeader"
import { Fragment } from "react"

const Tasks = () => {
    return (
    <Fragment>
    <Layout>
        <MainHeader title="My WorkHUB"/>
        <TaskListForm/>
    </Layout>
    <Outlet/>
    </Fragment>)
}

export default Tasks