import classes from './Checkbox.module.css'

const Checkbox = (props) => {

    return (
      <div className={`${props.className} ${classes["checkbox-wrapper"]} ${props.checked && classes['checked']} ${props.readOnly && classes['readonly']}`}>
          <input type="checkbox" 
          checked={props.checked}  
          onChange={!props.readOnly && props.onChange}
          onBlur={props.onBlur} 
          className={classes.checkbox}
          id={props.id}
          disabled={props.disabled}
          readOnly={props.readOnly}
          onClick={props.onClick && props.onClick}/>
          <label htmlFor={props.id}>{props.label}</label>
      </div>
    );
  };
  export default Checkbox;