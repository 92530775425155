import { useCallback, useState } from "react"
import { useSelector } from "react-redux"

const useHttp = () => {
    const [isLoading, setIsLoading] = useState()
    const [error, setError] = useState()

    const url = useSelector((state) => state.auth.url)

    const sendRequest = useCallback(async (requestConfig, applyData) => {
        setIsLoading(true)
        setError(null)
        var bearer = 'Bearer ' + localStorage.getItem('token');
        var requestUrl = url + requestConfig.url
        const headers = requestConfig.headers ? requestConfig.headers : {}
        const requestHeaders = { ...headers, 'Authorization': bearer}
        try{
        const response = await fetch(
            requestUrl, {
                method: requestConfig.method ? requestConfig.method : 'GET',
                headers: requestHeaders,
                body: requestConfig.body ? JSON.stringify(requestConfig.body) : null 
            }
        )
        
        if(!response.ok){
            const m = await response.json()
                let message = m.Message
                if(!m || m === '')
                message = 'something went wrong!'
            throw new Error(message)
        }
        
        const data = await response.json()

        applyData(data)

        }
        catch(err){
            setError(err.message || 'something went wrong!')
        }
        setIsLoading(false)
    },[url])

    return {
        isLoading,
        error,
        sendRequest
    }
}

export default useHttp