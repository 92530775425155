import { Fragment } from "react"
import { Outlet } from "react-router-dom"
import Layout from "../../components/layout/Layout"
import NewFeature from '../../components/Settings/NewFeatures/NewFeatures'

const NewFeatures = () => {
    return (
        <Fragment>
            <Layout>
                <NewFeature/>
            </Layout>
            <Outlet/>
        </Fragment>
    )
}

export default NewFeatures