import { useState } from "react"
import { useNavigate } from "react-router"

import useHttp from "../../../hooks/use-http"
import ButtonM from "../../UI/Buttons/Buttons/ButtonM"
import Input from "../../UI/Inputs/Input"
import MessageBox from "../../UI/Modals/MessageBox"
import classes from './JiraCredentials.module.css'

const JiraCredentials = () => {
    const navigate = useNavigate()
    const {isLoading, sendRequest: saveCredentials} = useHttp()

    const [email, setEmail] = useState('')
    const [emailInvalid, setEmailInvalid] = useState()

    const saveJiraCredentials = (e) => {
        e.preventDefault()
        if(!email || email === '' || !email.includes('@') || !email.includes('.')){
            //setEmailInvalid(true)
            //return
        }
        saveCredentials({url: `api/save-jira-credentials?email=${email}`}, handleResponse)
    }

    const handleResponse = (data) => {
        if(data === ''){
            localStorage.setItem('hasJiraCredentials', 'True')
            window.location.reload();
        }
        else{
            setEmailInvalid(data)
        }
    }

    return (
        
        <MessageBox className={classes.message} title='Log in to your Jira account' onClose={() => navigate(-1)}>
            <form className={classes['credentials-form']} onSubmit={saveJiraCredentials}>
            <Input className={classes.input} label='Email' onChange={(v) => {setEmail(v); setEmailInvalid(false)}} value={email} required invalid={emailInvalid} disabled={isLoading}/>
            <div className={classes.buttons}>
                <ButtonM onClick={()=>{}} loading={isLoading} type='submit'>Save</ButtonM>
            </div>
            </form>
        </MessageBox>
    )
}

export default JiraCredentials