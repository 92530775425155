import { useEffect, useRef, useState } from "react"
import TasksList from "../../../UI/Hub/TasksList"
import TaskListTotals from "../../../UI/Hub/TaskListTotals"
import TaskList from "./TaskList"
import TaskListItem from "./TaskListItem"
import classes from './Dashboard.module.css'
import { toDecimalWithCommas } from "../../../../functions/toDecimal"
import arrow from '../../../../images/Arrow - Down small.svg'
import listClasses from './TaskListItem.module.css'
import { useReactToPrint } from "react-to-print"
import { useSelector } from "react-redux"


const Dashboard = (props) => {
    const tasksRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => tasksRef.current,
    });

    const loadedAll = useSelector((state) => state.hub.loadedAllDashboards)
    const loadedSettings = useSelector((state) => state.hub.loadedSettings)

    const [totals, setTotals] = useState([])
    const [opened, setOpened] = useState(true)
    const [amountDisplayed, setAmountDisplayed] = useState(10)


    
    useEffect(()=>{
        if(props.dashboard.Totals){
            let totals = Object.keys(props.dashboard.Totals).map((key, index) => {
            return {
                name: key,
                value: key === 'Balance' ? '$' + toDecimalWithCommas(props.dashboard.Tasks.reduce((a, b) => a + (b.Balance || 0), 0)) : key === 'Total Tasks' ? props.dashboard.Tasks.length : props.dashboard.Totals[key]
            }
            })
            setTotals(totals)
        }
    },[props.dashboard])

        
    useEffect(()=>{
        if(loadedSettings){
            if(props.dashboard.Settings.NumberOfTasksToLoad && parseInt(props.dashboard.Settings.NumberOfTasksToLoad) > 0){
                if(props.dashboard.Tasks.length >= parseInt(props.dashboard.Settings.NumberOfTasksToLoad)){
                    setAmountDisplayed(parseInt(props.dashboard.Settings.NumberOfTasksToLoad))
                }
                else{
                    setAmountDisplayed(props.dashboard.Tasks.length)
                }
            }
            else{
                if(props.dashboard.Tasks.length >= 10){
                    setAmountDisplayed(10)
                }
                else{
                    setAmountDisplayed(props.dashboard.Tasks.length)
                }
            }
        }
    },[props.dashboard.Tasks.length, props.dashboard.Settings, loadedSettings])

    const showMoreHandler = () => {
        if(loadedAll > 0){
           const amountToLoad =  props.dashboard.Settings.NumberOfTasksToLoad && parseInt(props.dashboard.Settings.NumberOfTasksToLoad) > 0 ? parseInt(props.dashboard.Settings.NumberOfTasksToLoad) : 10
           if(amountDisplayed + amountToLoad > props.dashboard.Tasks.length){
               setAmountDisplayed(props.dashboard.Tasks.length)
           }
           else{
               setAmountDisplayed(prevState => prevState + amountToLoad)
           }
        }
        
    }

    const showAllHandler = () => {
        setAmountDisplayed(props.dashboard.Tasks.length)
    }
    
return <div ref={tasksRef}>{loadedSettings && <TasksList title={props.dashboard.Caption} 
            open={opened} onClick={() => setOpened(prevState => !prevState)} 
            total={loadedAll ? props.dashboard.Tasks.length + props.dashboard.CompletedTasks : 0} 
            completed={loadedAll ? props.dashboard.CompletedTasks : 0}
            actions={props.dashboard.Settings.DashboardActions}
            dashboardSettingId={props.dashboard.DashboardSettingID}
            onPrint={handlePrint}>
    <TaskListTotals totals={totals} balance={props.dashboard.Tasks.map(t => t.Balance)}/>
    <TaskList name={props.dashboard.DashboardSettingID} total={amountDisplayed}>
    {props.dashboard.Settings.ShowHeadersOnGrid && props.dashboard.Settings.ShowHeadersOnGrid === "True" && <div className={`${listClasses.task} ${listClasses.headers}`}>
        <div className={listClasses.details}>
        {props.dashboard.Tasks.length > 0 && props.dashboard.Tasks[0].FieldsToDisplay.length > 0 && props.dashboard.Tasks[0].FieldsToDisplay.map((f, i) =>{
                    return <p className={listClasses.detail} key={i}>{f.FieldName && f.FieldName.replace(/([a-z])([A-Z])/g, '$1 $2')}</p>
                } )}
            </div></div>}
        {props.dashboard.Tasks.slice(0, amountDisplayed).map(t => {
            return <TaskListItem key={Math.random()} task={t} dashboardSettingId={props.dashboard.DashboardSettingID} settings={props.dashboard.Settings}/>
        })}
    {props.dashboard.Tasks.length > amountDisplayed && loadedAll > 0 && <div className={classes.load}>
        <p className={classes['show-more']} onClick={showMoreHandler}>Load more<img src={arrow} alt=''/></p>
        <p className={classes['show-more']} onClick={showAllHandler}>Load all<img src={arrow} alt=''/></p>
        </div>}
    </TaskList>
</TasksList>}
</div>
}

export default Dashboard