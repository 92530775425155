
import { useEffect, useState } from 'react'
import Feature from './Feature'
import classes from './FeaturesList.module.css'
import { addMonths } from '../../../../functions/Dates/dateFunctions'
import { formatDate } from '../../../../functions/Dates/formatDate'

const FeaturesList = (props) => {
    const [features, setFeatures] = useState([])

    useEffect(()=>{
        calculateDates(props.features)
    },[props.features])

    const changedVotesHandler = (id, votes, myVotes) => {
        let fs = [...features]
        fs = fs.map(el => el.FeatureID === id ? {...el, Votes: votes, MyVotes: myVotes} : el);
        let f = fs.filter(feature => !['In Progress', 'Pending', 'To be Updated'].includes(feature.Status))
        f = f.sort((a, b) => b.Votes-a.Votes);
        let inProgress = fs.filter(feature => ['In Progress', 'Pending', 'To be Updated'].includes(feature.Status))
        
        calculateDates([...inProgress, ...f])
    }
    const calculateDates = (features) => {
        var date = new Date();
        var dt = date.getDate();
        date.setDate(15);
        if (dt >= 15) {
        date.setMonth(date.getMonth() + 1);
        }
        date.setHours(23, 59, 59, 0);
        if(props.lastFeatureDone && props.lastFeatureDone > addMonths(date, -1)){
            date = addMonths(date, 1)
        }
        let f = features.map(feature => {
            let compDate = addMonths(date, 0)
            date = addMonths(date, 1)
            return {...feature, CompletionDate: formatDate(compDate)}
        })
        setFeatures(f)
    }

    return (<div className={classes['features-list']}>
        
        <div className={classes["features-list__header"]}>
            <p className={classes.id}>Id</p>
            <p className={classes.title}>Summary</p>
            <p className={classes.voted}>Votes</p>
            <p className={classes["my-votes"]}>My Votes</p>
            <p className={classes.completion}>Projected <br/>Completion Date</p>
            <p className={classes.details}></p>
            <p className={classes.watch}></p>
            <p className={classes['status-container']}></p>
        </div>
        <div className={classes.features}>
            {features.map(f=> <Feature key={f.FeatureID} feature={f} onChangedVotes={changedVotesHandler}/>)}
        </div>
    </div>)
}

export default FeaturesList