import { Fragment, useEffect, useRef, useState } from 'react'

import classes from './TasksList.module.css'
import CircleProgressBar from './CircleProgressBar'
import DetailsItem from './DetailsItem'
import more from '../../../images/more_vert.svg'
import useOutsideAlerter from '../../../hooks/use-outside-alerter'

const TasksList = (props) => {
    const actionRef = useRef()
    const {clickedOutside, resetState} = useOutsideAlerter(actionRef)

    
    const [menuActions, setMenuActions] = useState([])
    const [showActions, setShowActions] = useState()
    const [print, setPrint] = useState()

    useEffect(()=>{
        if(props.actions){
            let _actions = props.actions.split(',')
            if(_actions){
                let a = _actions.map((action, i) => {
                    return <p key={i} className={classes['popup-item']} onClick={(e) => actionHandler(e, action.trim())}>{action.replace(/([A-Z])/g, ' $1').trim()
                }</p>
                })

                setMenuActions(a)
            }    
        }
        else{
            setMenuActions(<p className={classes['no-actions']} onClick={(e) => e.stopPropagation()}>No actions set up</p>)
        }
    },[props.actions])

    useEffect(()=>{
        if(clickedOutside){
          switch(actionRef.current.id){
              case 'actions': 
                  setShowActions(false)
                  break;
              default:
                  break;
          }
          resetState()
        }
      },[clickedOutside])

    useEffect(()=>{
        if(!showActions && print){
            props.onPrint()
            setPrint(false)
        }
    },[showActions])

    const actionHandler = (e, action) => {
        e.stopPropagation();
        switch(action){
            case 'PrintDashboard':
                setShowActions(false)
                setPrint(true)
                break;
            default:
                break;

        }
    }

    return  <Fragment>
        <DetailsItem open={props.open} onClick={props.onClick} title={props.title} header={
            <div className={classes.completed}>
                <p className={classes['completed-text']}>{props.completed}/{props.total} Completed</p>
                <CircleProgressBar percent={parseInt(props.completed) / parseInt(props.total) * 100}/>
                <div className={classes.action} ref={showActions ? actionRef : null} id='actions'>
                <img src={more} alt='' onClick={(e) => {e.stopPropagation(); setShowActions(prevState => ! prevState);}} />
                {showActions && <div className={classes.popup}>
                    {menuActions}
                    </div>}
            </div>
            </div>
        }>
        {props.children}
    </DetailsItem>
</Fragment>
}

export default TasksList