import { hubActions } from './hub-slice'
import { uiActions } from './ui-slice';
import store from './index'


export const fetchDashboards = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(hubActions.setDashboardsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + 'api/tasks',{
                headers:{
                    'Authorization': bearer,
                }

            });

            if (!response.ok) {
                const m = await response.json()
                let message = m.Message
                if(!m || m === '')
                message = 'Could not fetch dashboards'
                throw new Error(message)
            }
            const data = await response.json()
            return data;
        };

        try {
            const dashboardsData = await fetchData()
            dispatch(hubActions.loadDashboards({ dashboards: dashboardsData || [] }))
        } catch (error) {
            dispatch(uiActions.showNotification({status: 'error', message: error.message}))
        }

        finally {
            dispatch(hubActions.setDashboardsLoading(false))

        }
    };
};
export const fetchAllDashboardTasksAndTotals = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + 'api/allTasks',{
                headers:{
                    'Authorization': bearer,
                }

            });

            if (!response.ok) {
                const m = await response.json()
                let message = m.Message
                if(!m || m === '')
                message = 'Could not fetch dashboards'
                throw new Error(message)
            }
            const data = await response.json()
            return data;
        };

        try {
            const dashboardsData = await fetchData()
            dispatch(hubActions.loadAllTasks({ dashboards: dashboardsData || [] }))
        } catch (error) {
            console.log(error)
            dispatch(uiActions.showNotification({status: 'error', message: error.message}))
        }

    };
};


export const fetchListForNewTask = (dashboardId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(hubActions.setListForNewTaskLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + `api/listForNewTask?dashboardId=${dashboardId}`, {
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                throw new Error('Could not fetch list.')
            }
            const data = await response.json()
            return data;
        };

        try {
            const data = await fetchData()
            dispatch(hubActions.loadListForNewTask({ list: data || [] }))
        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
        finally {
            dispatch(hubActions.setListForNewTaskLoading(false))

        }
    };
};


export const fetchReminderTypes = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(hubActions.setReminderTypesLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + 'api/reminderTypes', {
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                throw new Error('Could not fetch parents list.')
            }
            const data = await response.json()
            return data;
        };

        try {
            const typesData = await fetchData()
            dispatch(hubActions.loadReminderTypes({ types: typesData || [] }))
        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
        finally {
            dispatch(hubActions.setReminderTypesLoading(false))

        }
    };
};


