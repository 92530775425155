import { Fragment, useEffect, useState } from "react"
import BackdropBlur from "../../../UI/Modals/BackdropBlur"
import { useNavigate, useSearchParams } from "react-router-dom"
import useHttp from "../../../../hooks/use-http"
import classes from './TaskDetails.module.css'
import { useDispatch, useSelector } from "react-redux"
import TextField from "../../../UI/Inputs/TextField"
import DetailsItem from "../../../UI/Hub/DetailsItem"
import LoadingSpinner from "../../../UI/LoadingSpinners/LoadingSpinner"
import ArrowRight from '../../../../images/right-datails.svg'
import ArrowLeft from '../../../../images/left - details.svg'
import ModelTitleClose from "../../../UI/Others/ModelTitleClose"
import TaskActions from "./TaskActions"
import TaskQueries from "./TaskQueries"
import ButtonMOut from "../../../UI/Buttons/Buttons-Outline/ButtonMOut"
import { uiActions } from "../../../../store/ui-slice"
import { fetchAllDashboardTasksAndTotals, fetchDashboards } from "../../../../store/hub-actions"
import ChangesMessageBox from '../../../UI/MessageBoxs/ChangesMessageBox'

const TaskDetails = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const {sendRequest: getDetails, error,  isLoading: getDetailsLoading} = useHttp()
    const {sendRequest:saveNote, isLoading:noteLoading } = useHttp()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const dashboards = useSelector((state) => state.hub.dashboards)

    const [selectedTask, setSelectedTask] = useState()
    const [selectedTaskDetails, setSelectedTaskDetails] = useState()
    const [detailsOpened, setDetailsOpened] = useState(true)
    const [note, setNote] = useState('')
    const [dashboardSettings, setDashboardSettings] = useState()
    const [showMessage, setShowMessage] = useState()

    useEffect(()=>{
        if(searchParams.get('setting') && searchParams.get('id')){
            getTaskDetails()
        }
    },[searchParams])

    const getTaskDetails = () => {
            getDetails({url: `api/taskDetails?dashboardSettingId=${searchParams.get('setting')}&id=${searchParams.get('id')}`}, handleResponse)
    }

    useEffect(()=>{
        if(error){
            dispatch(uiActions.showNotification({status: 'error', message: error}))
        }
    },[error])

    useEffect(()=>{
        if(dashboards.length > 0){
            const dashboard = dashboards.find(d => d.DashboardSettingID === parseInt(searchParams.get('setting')))
            if(dashboard){
                setDashboardSettings(dashboard.Settings)
            }
        }
    },[dashboards])


    useEffect(()=>{
        if(dashboards.length > 0 && selectedTaskDetails){
            const dashboard = dashboards.find(d => d.DashboardSettingID === parseInt(searchParams.get('setting')))
            if(dashboard){
                const task = dashboard.Tasks.find(t=> t.ID === parseInt(searchParams.get('id')))
                console.log(task)
                if(task){
                    setSelectedTask(task)
                }
            }
        }
    },[selectedTaskDetails, dashboards])

    useEffect(()=>{
        if(note === '' && showMessage){
            setShowMessage(null)
            if(showMessage === 'close'){
                closeDetailHandler() 
             }
             else {
                 navigateTaskHandler(showMessage)
             }     
        }
    },[note])
    
    const handleResponse = (data) => {
        if(data){
            console.log(data)
            if(!data.ID || data.ID === 0){
                navigate('..')
            }
            else{
                setSelectedTaskDetails(data)
                setNote('')
            }
        }
    }

    const navigateTaskHandler = (forward) => {
        if(note && note !== ''){
            setShowMessage(forward)
            return;
        }
        const dashboard = dashboards.find(d => d.DashboardSettingID === parseInt(searchParams.get('setting')))
        if(!dashboard){ return; }
        const taskIndex = dashboard.Tasks.findIndex(t => t.ID === parseInt(searchParams.get('id')))
        if(taskIndex=== undefined  || (forward && dashboard.Tasks.length === taskIndex + 1) || (!forward && taskIndex === 0) ){ return; }
        const i = forward ? taskIndex + 1 : taskIndex - 1
        const id = dashboard.Tasks[i].ID;
        searchParams.set('id', id)
        setSearchParams(searchParams)
    }

    const closeDetailHandler = () => {
        if(note && note !== ''){
            setShowMessage('close')
            return;
        }
        navigate('..')
    }

    const changeNoteHandler = (value) => {
        setNote(value)
    }

    const saveNoteHandler = () => {
        const n = note
        setNote('')
        saveNote({ url: `api/saveNote?dashboardSettingId=${searchParams.get('setting')}&id=${searchParams.get('id')}&reminderId=${selectedTask.ReminderID}&note=${n}`, headers: { 'Accept': 'application/json', 'content-type': 'application/json' }}, handleNoteResponse)
    }

    const handleNoteResponse = (data) => {
        if(data){
            dispatch(uiActions.showSidePopup({status: 'success', header: 'Successfuly saved reminder'}))
            dispatch(fetchAllDashboardTasksAndTotals())
            getTaskDetails()
        }
    
    }

    const closeMessgaeHandler = () => {
        setNote('')
    }

    return(
        <Fragment>
        <BackdropBlur className={classes.backdrop}>
            <div className={classes.wrapper}>
                <ModelTitleClose title='Task Detail' onClose={closeDetailHandler}/>
            <div className={classes.detail}>
                {selectedTask && selectedTaskDetails && <div className={classes.form}>
                <h2 className={classes.header}>{dashboardSettings &&  dashboardSettings.RowFormatText || 'Call'} {dashboardSettings && dashboardSettings.RowFormatLastNameFirst && dashboardSettings.RowFormatLastNameFirst.toLowerCase() === 'true' ? `${selectedTask.LastName} ${selectedTask.FirstName}` : `${selectedTask.FirstName} ${selectedTask.LastName}`} </h2>
                <TaskActions task={selectedTask} onChanged={getTaskDetails} settings={dashboardSettings} onNavigate={() => navigateTaskHandler(true)} dashboardSettingId={parseInt(searchParams.get('setting'))} actions={dashboardSettings.ActionsOnDropdown}/>
                {!noteLoading && !getDetailsLoading && <TextField label='Notes' value={note} onChange={changeNoteHandler}>
                {note && note !== '' && <div className={classes['save-note']}><ButtonMOut onClick={saveNoteHandler}>Save</ButtonMOut></div>}
                </TextField>}
                <DetailsItem title='More Details' open={detailsOpened} onClick={() => setDetailsOpened(prevState => !prevState)}>
                    <div className={classes['details']}>
                        <div className={classes['task-fields']}>
                        {Object.entries(selectedTaskDetails.TaskFields).map(([_key, value]) => {
                            return <div key={_key} className={classes['task-field']}>
                                <p className={classes['field-key']}>{_key}</p>
                                <p className={classes['field-value']}>{value}</p>
                            </div>
                        })}
                        <div className={classes['task-field']}>
                                <p className={classes['field-key']}>Phone Numbers</p>
                                {Object.entries(selectedTaskDetails.PhoneNumbers).map(([_key, value]) => {
                                    return <p key={_key} className={classes['field-value']}>{value}</p>
                                })}
                            </div>
                        </div>
                        <div className={classes.line}/>
                        <TaskQueries queries={selectedTaskDetails.TaskQueries}/>
                    </div>
                </DetailsItem>
                </div>
                }
                {(!selectedTask || !selectedTaskDetails) && <LoadingSpinner className={classes.spinner}/>}
                <div onClick={() => navigateTaskHandler(true)} className={classes.right}><img src={ArrowRight} alt=''/></div>
                <div onClick={() => navigateTaskHandler(false)} className={classes.left}><img src={ArrowLeft} alt=''/></div>
            </div>
            </div>
        </BackdropBlur>
        {showMessage && <ChangesMessageBox onSave={saveNoteHandler} onNoSave={closeMessgaeHandler} onCancel={() => setShowMessage(false)}/>}
        </Fragment>
    )
}

export default TaskDetails