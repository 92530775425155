
import { useEffect, useRef, useState } from 'react'
import classes from './TextField.module.css'


const TextField = (props) => {
    const defaultValue= useRef(props.value)
    const [defaultValueSet, setDefaulValueSet] = useState()
    const focusRef = useRef()

    useEffect(() => {
        if(props.value && props.value !== '' && !defaultValueSet){
            defaultValue.current = props.value
            setDefaulValueSet(true)
        }
    },[props.value, defaultValueSet])

    useEffect(()=>{
        if(props.autoFocus){
            focusRef.current.focus()
        }
    },[props.autoFocus])

    const changeInputHandler = (e) => {
        props.onChange(e.target.innerHTML)
        setDefaulValueSet(true)
    }

    return (
        <div className={`${classes['text-area__wrapper']} ${props.invalid && classes['invalid-input']} ${props.className}`}>
            <label>{props.label} {props.required && <span className={classes['required-input']}>*</span>}</label>
            <div className={classes.textarea} id={props.id}
            contentEditable={!props.readOnly ? 'true': 'false'} 
            suppressContentEditableWarning={true} 
            onInput={changeInputHandler} 
            dangerouslySetInnerHTML={{ __html: defaultValue.current}}
            ref={focusRef}/>
            {props.children}
        </div>
    )
}

export default TextField