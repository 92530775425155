function addCommas(number) {
    // Convert the number to a string
    var numString = number.toString();
    //remove all old commas
    var numberString = numString.replaceAll(',','')
    //remove the decimal before adding
    if(numberString.includes('.')){
        numberString = numberString.substring(0, numberString.indexOf('.'))
    }
    // Split the string into an array of digits
    const digits = numberString.split('');
    // Reverse the array of digits
    digits.reverse();
    // Add a comma every three digits
    for (let i = 3; i < digits.length; i += 4) {
      digits.splice(i, 0, ',');
    }
    // Reverse the array of digits back to its original order
    digits.reverse();
    // Join the array of digits back into a string
    var formattedNumber = digits.join('');
    if(numString.includes('.')){
        formattedNumber = formattedNumber + numString.substring(numString.indexOf('.'), numString.length)
    }
    // Return the formatted number
    return formattedNumber;
  }

  export function removeCommas(number){
    if(number){
        return number.toString().replaceAll(',','')
    }
  }

  export default addCommas