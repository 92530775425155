import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    statement: [],
    loadedStatement: false,
    selectedPayment: {},
    selectedCharge: {},
    isLoading: false,
    balance: '',
}

const statementsSlice = createSlice({
    name: "statements",
    initialState,
    reducers: {
        loadStatement(state, action) {
            state.loadedStatement = true
            state.statement = action.payload.statement;
        },
        setSelectedPayment(state, action){
            var selected = state.statement.find(s => s.PaymentID === action.payload)
            if(selected){
                state.selectedPayment = selected
            }
        },
        setSelectedPledge(state, action){
            var selected = state.statement.find(s => s.PledgeID === action.payload)
            if(selected){
                state.selectedCharge = selected
            }
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        resetStatementsInfo(state) {
            state.statement = []
        },
        resetSelectedPayment(state){
            state.selectedPayment = {}
        },
        resetSelectedCharge(state){
            state.selectedCharge = {}
        },
        setBalance(state, action){
            if(action.payload > 0){
                state.balance = action.payload
            }
            else{
                state.balance = 0
            }
        },
        resetState:() => initialState,
    },
});

export const statementsActions = statementsSlice.actions;

export default statementsSlice;
