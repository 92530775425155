import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    organizations: [],
    isLoading: false,
}

const organizationsSlice = createSlice({
    name: "organizations",
    initialState,
    reducers: {
        loadOrganizations(state, action) {
            state.organizations = action.payload.organizations;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        resetState:() => initialState,
    },
});

export const organizationsActions = organizationsSlice.actions;

export default organizationsSlice;
