import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tenders: [],
    selectedTender: {},
    isLoading: false,
    activeCCs: [],
    activeOJCs: [],
    activePledgers: [],
    activeMatbias: [],
    activeTheDonorsFunds: [],
    activeACHs: [],
    pendingCheck: {},
}

const tendersSlice = createSlice({
    name: "tenders",
    initialState,
    reducers: {
        loadTenders(state, action) {
            state.tenders = action.payload.tenders;
            if (action.payload.selectedTenderID) {
                var selectedTender = state.tenders.find(tender => tender[Object.keys(tender)[0]] === action.payload.selectedTenderID)
            }
              if (selectedTender) {
                    state.selectedTender = selectedTender
                }
            
            else if (action.payload.tenders.length > 0 && action.payload.tenders[0].DonorTenderCreditCardId) {
                selectedTender = state.tenders.find(tender => tender.Active === true)
                if (selectedTender) {
                    state.selectedTender = selectedTender
                }
                else {
                    state.selectedTender = {}
                }
            }
            else{
                if (state.tenders.length !== 0) {
                    state.selectedTender = state.tenders[0]
                }
                else {
                    state.selectedTender = {}
                }
            }

        },
        setSelectedTender(state, action) {
            const id = action.payload
            const selectedTender = state.tenders.find(tender => tender[Object.keys(tender)[0]] === id)
            if (selectedTender) {
                state.selectedTender = selectedTender
            }
        },
        resetTenderList(state) {
            state.selectedTender = {}
            state.tenders = []
            state.activeCCs = []
            state.activeACHs = []
            state.activeOJCs = []
            state.activeTheDonorsFunds = []
            state.activePledgers = []
            state.activeMatbias = []
        },
        resetSelectedTender(state) {
            state.selectedTender = {}
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        loadTendersType(state, action){
            if(action.payload.tenders.length !== 0){
                const activeList = action.payload.tenders.filter(tender => tender.Active === true)
                if(activeList){
                    switch(action.payload.type){
                        case 'CC':
                            state.activeCCs = activeList
                            break;
                        case 'ACH':
                            state.activeACHs = activeList
                            break;
                        case 'OJC':
                            state.activeOJCs = activeList
                            break;
                        case 'TheDonorsFund':
                            state.activeTheDonorsFunds = activeList
                            break;
                        case 'Pledger':
                            state.activePledgers = activeList
                            break;
                        case 'Matbia':
                            state.activeMatbias = activeList
                            break;
                        default:
                            return;
                    }
                }
            }
        },
        storePendingCheck(state, action){
            state.pendingCheck = action.payload.check
        },
        resetState:() => initialState,
    },
});

export const tendersActions = tendersSlice.actions;

export default tendersSlice;
