
import { forwardRef } from 'react'
import classes from './Input.module.css'

const Input = forwardRef((props, ref) => {

    const changeInputHandler = (e) => {
        props.onChange(e.target.value)
    }

    return (
        <div className={`${classes['form-input__container']} ${props.invalid && classes['invalid-input']} ${props.className}`} ref={ref}>
        <div className={`${classes['form-group']} ${props.readOnly ? classes['readonly'] : ''}`}>
            <label>{props.label} {props.required && <span className={classes['required-input']}>*</span>}</label>
            <input className={props.inputClassName}
                placeholder={props.placeholder}
                type={props.type}
                onChange={changeInputHandler}
                value={props.value || ''}
                onBlur={props.onBlur}
                min={props.min}
                disabled={props.disabled}
                pattern={props.pattern}
                max={props.max}
                accept={props.accept}
                readOnly={props.readOnly}
                autoFocus={props.autoFocus}
                autoComplete={props.autoComplete ? props.autoComplete : 'on'}
              >
            </input>
            {props.children}
            </div>
            {props.invalid && <p>{props.invalid}</p>}
        </div>
    )
})

export default Input