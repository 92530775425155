
export const getUpdateMessageBody = (obj, success = true) => {
    if(success){
        return `Successfully updated ${obj}`
    }
    else{
        return `Error updating ${obj}`
    }
}


export const getAddMessageBody = (obj, success = true) => {
    if(success){
        return `Successfully added ${obj}`
    }
    else{
        return `Error saving ${obj}`
    }
}

export const getSaveMessageBody = (obj, success = true) => {
    if(success){
        return `Successfully saved ${obj}`
    }
    else{
        return `Error saving ${obj}`
    }
}

export const getDeleteMessageBody = (obj, success = true) => {
    if(success){
        return `Successfully deleted ${obj}`
    }
    else{
        return `Error deleting ${obj}`
    }
}