import { useCallback } from "react"
import { authActions } from "../store/auth-slice"
import { useDispatch } from "react-redux"
import { accountsActions } from "../store/accounts-slice"
import { campaignsActions } from "../store/campaigns-slice"
import { donorsActions } from "../store/donors-slice"
import { pledgesActions } from "../store/pledges-slice"
import { filtersActions } from "../store/filters-slice"
import { organizationsActions } from "../store/organizations-slice"
import { paymentsActions } from "../store/payments-slice"
import { paymentTypesActions } from "../store/paymentTypes-slice"
import { tendersActions } from "../store/tenders-slice"
import { solicitorsActions } from "../store/solicitors-slice"
import { shulsActions } from "../store/shuls-slice"
import { statementsActions } from "../store/statements-slice"
import { settingsActions } from "../store/settings-slice"
import { studentsActions } from "../store/students-slice"
import { hubActions } from "../store/hub-slice"
import { useNavigate } from "react-router"


const useResetState = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const resetState = useCallback(()=>{
        //navigate('/login')
        dispatch(authActions.logout())
        dispatch(accountsActions.resetState())
        dispatch(campaignsActions.resetState())
        dispatch(donorsActions.resetState())
        dispatch(pledgesActions.resetState())
        dispatch(filtersActions.resetState())
        dispatch(organizationsActions.resetState())
        dispatch(paymentsActions.resetState())
        dispatch(paymentTypesActions.resetState())
        dispatch(tendersActions.resetState())
        dispatch(solicitorsActions.resetState())
        dispatch(shulsActions.resetState())
        dispatch(statementsActions.resetState())
        dispatch(studentsActions.resetState())
        dispatch(settingsActions.resetState())
        dispatch(hubActions.resetState())
        
    },[dispatch])

    return {
        resetState
    }
}
export default useResetState