
import Button from './Button'
import classes from './ButtonL.module.css'

const ButtonL = (props) => {
    return (
        <Button className={`${classes['btn-l']}  ${props.className}`} type={props.type ? props.type : 'button'} onClick={props.onClick} disabled={props.disabled} loading={props.loading}>{props.children}</Button>
    )
}

export default ButtonL