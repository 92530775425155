
import classes from './TextButton.module.css'

const TextButton = (props) => {

    return (
        <button className={`${classes['btn-cancel']} ${props.className}`} type='button' onClick={props.onClick}>{props.children}</button>
    )
}

export default TextButton