
const CurrentDateForDateInput = () => {
    var date = new Date();

var day = ("0" + date.getDate()).slice(-2);
var month = ("0" + (date.getMonth() + 1)).slice(-2);

var today = date.getFullYear() + "-" + (month) + "-" + (day);
return today;
}

export const FormatDateForDateInput = (date) => {
    var d = new Date(date);

    var day = ("0" + d.getDate()).slice(-2);
    var month = ("0" + (d.getMonth() + 1)).slice(-2);
    
    var today = d.getFullYear() + "-" + (month) + "-" + (day);
    return today;
    
}
export default CurrentDateForDateInput