import { Fragment, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router"
import ButtonM from "../../../UI/Buttons/Buttons/ButtonM"
import PinkTextButton from "../../../UI/Buttons/TextButtons/PinkTextButton"
import FixedMiddleBar from "../../../UI/FixedBottomBar/FixedMiddleBar"

import Input from "../../../UI/Inputs/Input"
import TextField from "../../../UI/Inputs/TextField"
import MessageBox from "../../../UI/Modals/MessageBox"
import ModalNew from "../../../UI/Modals/ModalNew"
import ImageUpload from "../../../UI/Others/ImageUpload"
import classes from './AddNewFeature.module.css'


const AddNewFeature = () => {

    const navigate = useNavigate()
    const url = useSelector((state) => state.auth.url)

    const [summary, setSummary] = useState('')
    const [summaryInvalid, setSummaryInvalid] = useState()
    const [description, setDescription] = useState('')
    const [descriptionInvalid, setDescriptionInvalid] = useState()
    const [uploadedImages, setUploadedImages] = useState([])
    const [isLoading, setIsLoading] = useState()
    const [error, setError] = useState()
    const [successMessage, setSuccessMessage] = useState()



    const closeAddFeatureHandler = () => {
        navigate(-1)
    }

    const changeUploadedImages = (files) => {
        setUploadedImages(files)
    }

    const saveFeatureHandler = () => {
        if(!summary || summary.trim() === ''){
            setSummaryInvalid(true)
            return;
        }
        if(!description || description.trim() === ''){
            setDescriptionInvalid(true)
            return;
        }
        saveFeature()
    }

    const saveFeature = async () => {
        setIsLoading(true)
        var bearer = 'Bearer ' + localStorage.getItem('token');

        var form = new FormData()
        form.append("Summary", summary)
        form.append("Description", description)
        uploadedImages.forEach(image => {
            form.append(image.name, image)

        });
        const response = await fetch(`${url}api/featurevoting`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': bearer
                },
                enctype: "multipart/form-data",
                body: form
            })
        const data = await response.json()
        if(!data){
            setError(true)
        }
        else{
            setSuccessMessage(true)
        }
        setUploadedImages([])
        setIsLoading(false)
    }


    return <Fragment><ModalNew title='Feature Request Form' onClose={closeAddFeatureHandler}>
        <Input label='Summary' required onChange={(v) => {setSummary(v); setSummaryInvalid(false)}} value={summary} invalid={summaryInvalid}/>
        <TextField label='Descrioption' required onChange={(v) => {setDescription(v); setDescriptionInvalid(false)}} value={description} className={classes.description} invalid={descriptionInvalid}/>
        <ImageUpload title='Add Attachments' onChange={changeUploadedImages} selectedFiles={uploadedImages}/>
        <FixedMiddleBar>
            <PinkTextButton onClick={closeAddFeatureHandler}>Cancel</PinkTextButton>
            {error && <p className="invalid-message">An error accured while saving, please try again.</p>}
            <ButtonM loading={isLoading} onClick={saveFeatureHandler}>Save</ButtonM>
        </FixedMiddleBar>

    </ModalNew>
    {successMessage && <MessageBox onClose={() => {navigate(-1)}}>
            <label className={classes['success-message']}>We got your request. We're on it.<br/>Check your email for updates.</label>
            </MessageBox>}
    </Fragment>
}

export default AddNewFeature