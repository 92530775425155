import classes from './LoadingSpinnerButton.module.css'

const LoadingSpinnerButton = () => {
    return (
        <div className={classes.container}>
        <span className={classes.loader}></span> 
        </div>  
    )
}

export default LoadingSpinnerButton