import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    paymentTypes: [],
    selectedPaymentType: {},
    isLoading: false,
     }

const paymentTypesSlice = createSlice({
    name: "paymentTypes",
    initialState,
    reducers: {
        loadPaymentTypes(state, action) {
            state.paymentTypes = action.payload.paymentTypes;
        },
        setSelectedPaymentType(state, action) {
            const id = action.payload
            var selectedPaymentType
            if (id !== null){
                selectedPaymentType  = state.paymentTypes.find(paymentType => paymentType.ID === id)
            }else{
                selectedPaymentType = state.paymentTypes.find(paymentType => paymentType.Default === true)
            }
            if (selectedPaymentType) {
                state.selectedPaymentType = selectedPaymentType
            }
        },
        resetSelectedPaymentType(state) {
            state.selectedPaymentType = {}
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        resetState:() => initialState,
    },
});

export const paymentTypesActions = paymentTypesSlice.actions;

export default paymentTypesSlice;
