import classes from './ListItem.module.css'

const ListItem = (props) => {

    return(
        <div className={`${props.className} ${classes['list-item']}`} onClick={props.onClick ? props.onClick : ()=>{}}>
            {props.children}
        </div>
    )
}

export default ListItem