import React, { useState, Fragment } from 'react'
import { NavLink } from 'react-router-dom'

import donors from '../../../images/Nav-Donors.svg'
import donorsActive from '../../../images/Nav-Donors active.svg'
import payments from '../../../images/Nav-Payments.svg'
import paymentsActive from '../../../images/Nav-Payments active.svg'
import recPayments from '../../../images/Nav-RecPayments.svg'
import recPaymentsActive from '../../../images/Nav-RecPayments active.svg'
import pledges from '../../../images/Nav-Pledges.svg'
import pledgesActive from '../../../images/Nav-Pledges active.svg'
import recPledges from '../../../images/Nav-RecPledges.svg'
import recPledgesActive from '../../../images/Nav-RecPledges active.svg'
import setting from '../../../images/Nav-Settings.svg'
import settingActive from '../../../images/Nav-Settings active.svg'
import features from '../../../images/Nav-Features.svg'
import featuresActive from '../../../images/Nav-Features active.svg'
import classes from './MainSidebar.module.css'
import { useSelector } from 'react-redux'

const SidebarNav = (props) => {

    //#region hooks 


    const [donorsSrc, setDonorsSrc] = useState(donors)
    const [paymentsSrc, setPaymentsSrc] = useState(payments)
    const [recPaymentsSrc, setRecPaymentsSrc] = useState(recPayments)
    const [pledgesSrc, setPledgesSrc] = useState(pledges)
    const [recPledgesSrc, setRecPledgesSrc] = useState(recPledges)
    const [settingsSrc, setSettingsSrc] = useState(setting)
    const [featuresSrc, setFeaturesSrc] = useState(features)
    const [hubSrc, setHubSrc] = useState(donors)

    const allowHub = useSelector((state) => state.auth.hub)

    //#endregion

    //#region event handlers
    const donorHoverHandler = () => {
        setDonorsSrc(donorsActive)
    }

    const donorLeaveHandler = () => {
        setDonorsSrc(donors)
    }

    const paymentsHoverHandler = () => {
        setPaymentsSrc(paymentsActive)
    }

    const paymentsLeaveHandler = () => {
        setPaymentsSrc(payments)
    }

    const recPaymentsHoverHandler = () => {
        setRecPaymentsSrc(recPaymentsActive)
    }

    const recPaymentsLeaveHandler = () => {
        setRecPaymentsSrc(recPayments)
    }

    const pledgesHoverHandler = () => {
        setPledgesSrc(pledgesActive)
    }

    const pledgesLeaveHandler = () => {
        setPledgesSrc(pledges)
    }

    const recPledgesHoverHandler = () => {
        setRecPledgesSrc(recPledgesActive)
    }

    const recPledgesLeaveHandler = () => {
        setRecPledgesSrc(recPledges)
    }

    const SettingsHoverHandler = () => {
        setSettingsSrc(settingActive)
    }

    const SettingsLeaveHandler = () => {
        setSettingsSrc(setting)
    }

    const featuresHoverHandler = () => {
        setFeaturesSrc(featuresActive)
    }

    const featuresLeaveHandler = () => {
        setFeaturesSrc(features)
    }

    const hubHoverHandler = () => {
        setHubSrc(donorsActive)
    }

    const hubLeaveHandler = () => {
        setHubSrc(donors)
    }
    //#endregion

    //#region nav items active/not active
    const activeDonors = <Fragment><div><img src={donorsActive} alt='Donors' /></div><label>Donors</label></Fragment>
    const inActiveDonors = <Fragment><div><img src={donorsSrc} alt='Donors' /></div><label>Donors</label></Fragment>

    const activePayments = <Fragment><div><img src={paymentsActive} alt='Payments' /></div><label>Payments</label></Fragment>
    const inActuvePayments = <Fragment><div><img src={paymentsSrc} alt='Payments' /></div><label>Payments</label></Fragment>

    const activeRecPayments = <Fragment><div><img src={recPaymentsActive} alt='Payments' /></div><label>Rec Payments</label></Fragment>
    const inActuveRecPayments = <Fragment><div><img src={recPaymentsSrc} alt='Payments' /></div><label>Rec Payments</label></Fragment>

    const activePledges = <Fragment><div><img src={pledgesActive} alt='Pledges' /></div><label>Pledges</label></Fragment>
    const inActivePledges = <Fragment><div><img src={pledgesSrc} alt='Pledges' /></div><label>Pledges</label></Fragment>

    const activeRecPledges = <Fragment><div><img src={recPledgesActive} alt='Pledges' /></div><label>Rec Pledges</label></Fragment>
    const inActiveRecPledges = <Fragment><div><img src={recPledgesSrc} alt='Pledges' /></div><label>Rec Pledges</label></Fragment>

    const activeSettings = <Fragment><div><img src={settingActive} alt='Settings' /></div><label>Settings</label></Fragment>
    const inActiveSettings = <Fragment><div><img src={settingsSrc} alt='Settings' /></div><label>Settings</label></Fragment>

    const activeFeatures = <Fragment><div><img src={featuresActive} alt='Features' /></div><label>Requested Features</label></Fragment>
    const inActiveFeatures = <Fragment><div><img src={featuresSrc} alt='Features' /></div><label>Requested Features</label></Fragment>

    const activeHub = <Fragment><div><img src={donorsActive} alt='Features' /></div><label>Hub</label></Fragment>
    const inActiveHub = <Fragment><div><img src={hubSrc} alt='Features' /></div><label>Hub</label></Fragment>

    //#endregion
    
    return (
        <Fragment>
            {allowHub && <li className={classes['main-sidebar__item']} onMouseOver={hubHoverHandler} onMouseOut={hubLeaveHandler} onClick={() => props.onClose ? props.onClose() : {}}>
                    <NavLink to="/hub"
                        className={(navData) => `${classes['main-sidebar__nav']} ${navData.isActive ? classes.active : ''}`}
                        children={(navData) => navData.isActive ? activeHub : inActiveHub}
                    />
                </li>}
                <li className={classes['main-sidebar__item']} onMouseOver={donorHoverHandler} onMouseOut={donorLeaveHandler} onClick={() => props.onClose ? props.onClose() : {}}>
                    <NavLink to="/donors-list"
                        className={(navData) => `${classes['main-sidebar__nav']} ${navData.isActive ? classes.active : ''}`}
                        children={(navData) => navData.isActive ? activeDonors : inActiveDonors}
                    />
                </li>
                <li className={classes['main-sidebar__item']} onMouseOver={paymentsHoverHandler} onMouseOut={paymentsLeaveHandler} onClick={() => props.onClose ? props.onClose() : {}}>
                    <NavLink to="/paymentslist"
                        className={(navData) => `${classes['main-sidebar__nav']} ${navData.isActive ? classes.active : ''}`}
                        children={(navData) => navData.isActive ? activePayments : inActuvePayments}
                    />
                </li>
                <li className={classes['main-sidebar__item']} onMouseOver={recPaymentsHoverHandler} onMouseOut={recPaymentsLeaveHandler} onClick={() => props.onClose ? props.onClose() : {}}>
                    <NavLink to="/rec-payments-list"
                        className={(navData) => `${classes['main-sidebar__nav']} ${navData.isActive ? classes.active : ''}`}
                        children={(navData) => navData.isActive ? activeRecPayments : inActuveRecPayments}
                    />
                </li>
                <li className={classes['main-sidebar__item']} onMouseOver={pledgesHoverHandler} onMouseOut={pledgesLeaveHandler} onClick={() => props.onClose ? props.onClose() : {}}>
                    <NavLink to="/pledges-list"
                        className={(navData) => `${classes['main-sidebar__nav']} ${navData.isActive ? classes.active : ''}`}
                        children={(navData) => navData.isActive ? activePledges : inActivePledges}
                    />
                </li>
                <li className={classes['main-sidebar__item']} onMouseOver={recPledgesHoverHandler} onMouseOut={recPledgesLeaveHandler} onClick={() => props.onClose ? props.onClose() : {}}>
                    <NavLink to="/rec-pledges-list"
                        className={(navData) => `${classes['main-sidebar__nav']} ${navData.isActive ? classes.active : ''}`}
                        children={(navData) => navData.isActive ? activeRecPledges : inActiveRecPledges}
                    />
                </li>
                <li className={classes['main-sidebar__item']} onMouseOver={SettingsHoverHandler} onMouseOut={SettingsLeaveHandler} onClick={() => props.onClose ? props.onClose() : {}}>
                    <NavLink to="/settings"
                        className={(navData) => `${classes['main-sidebar__nav']} ${navData.isActive ? classes.active : ''}`}
                        children={(navData) => navData.isActive ? activeSettings : inActiveSettings}
                    />
                </li>
                <li className={classes['main-sidebar__item']} onMouseOver={featuresHoverHandler} onMouseOut={featuresLeaveHandler} onClick={() => props.onClose ? props.onClose() : {}}>
                    <NavLink to="/new-features"
                        className={(navData) => `${classes['main-sidebar__nav']} ${navData.isActive ? classes.active : ''}`}
                        children={(navData) => navData.isActive ? activeFeatures : inActiveFeatures}
                    />
                </li>

        </Fragment>
    )
}

export default SidebarNav