import Checkbox from './Checkbox';

import classes from './Checkbox.module.css'

const CheckboxS = (props) => {

    return (
      <Checkbox className={`${props.className} ${classes.small}`}
          checked={props.checked}  
          onChange={props.onChange}
          onBlur={props.onBlur}
          readOnly={props.readOnly} 
          disabled={props.disabled}
          id={props.id}
          label={props.label}
          onClick={props.onClick && props.onClick}
      />
    );
  };
  export default CheckboxS;