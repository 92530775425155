
import classes from './Badge.module.css'

const Badge = (props) => {
    var cssClasses
    switch (props.status) {
        case 'Waiting for support':
            cssClasses = `${classes.badge} ${classes.regular} ${props.className}`
            break;
        case 'error':
        case 'Deleted':
            cssClasses = `${classes.badge} ${classes.error} ${props.className}`
            break;
        case 'success':
        case 'Successful':
        case 'Payment':
        case 'Resolved':
            cssClasses = `${classes.badge} ${classes.success} ${props.className}`
            break;
        case 'info':
        case 'Charge':
        case 'In Progress':
            cssClasses = `${classes.badge} ${classes.info} ${props.className}`
            break;
        case 'warning':
            cssClasses = `${classes.badge} ${classes.warning} ${props.className}`
            break;
        default:
            cssClasses = `${classes.badge} ${classes.warning} ${props.className}`


    }

    return (
        <label className={`${props.className} ${cssClasses}`}>{props.children}</label>
    )
}

export default Badge