
import classes from './DetailsItem.module.css'
import ArrowDown from '../../../images/Arrow - Down 2.svg'
import Arrowup from '../../../images/Arrow - Up 2.svg'


const DetailsItem = (props) => {

    return(
        <div className={classes['detail-item']}>
            <div className={`${classes['detail-item__header']} ${props.open === true && classes['detail-item__header-open']}`} onClick={props.onClick}>
            <div className={classes.header}>
                <img src={props.open ? Arrowup : ArrowDown} alt=''/>
                <h3 className={classes.title}>{props.title}</h3>
            </div>  
                {props.header}
            </div>
            {props.open && <div className={classes['detail-item__body']}>{props.children}</div>}
        </div>
    )
}

export default DetailsItem