export const formatDate = (date) => {
    const d = new Date(date)

    const yyyy = d.getFullYear();
    let mm = d.getMonth() + 1; // Months start at 0!
    let dd = d.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formatted = mm + '/' + dd + '/' + yyyy;
    return formatted
}

export const formatDateWithDayName = (date) => {
    const d = new Date(date)

    const day = d.toLocaleString('en-us', {  weekday: 'long' });

    const yyyy = d.getFullYear();
    let mm = d.getMonth() + 1; // Months start at 0!
    let dd = d.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formatted = mm + '/' + dd + '/' + yyyy;
        return day + ', ' + formatted
}