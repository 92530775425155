
import React, { Fragment, useEffect, useState } from 'react'
import formatAddress from '../../../../functions/formatAddress'
import formatPhone from '../../../../functions/formatPhone'
import classes from './DonorSearchItem.module.css'

const DonorSearchItem = React.forwardRef((props, ref) => {
    const [address, setAddress] = useState()
    const [phoneHome, setPhoneHome] = useState()
    const [phoneMobile, setPhoneMobile] = useState()

    useEffect(() => {
        const address = formatAddress(props.option.address)
        setAddress(address)
    }, [props.option.address])

    useEffect(() => {
        const phone = formatPhone(props.option.phoneHome)
        setPhoneHome(phone)
    }, [props.option.phoneHome])

    useEffect(() => {
        const phone = formatPhone(props.option.phoneMobile)
        setPhoneMobile(phone)
    }, [props.option.phoneMobile])

    return (
        <Fragment>
            <li
                ref={ref}
                id='listItem'
                tabIndex={0}
                onKeyDown={props.onKeyDown}
                onClick={props.onClick}
                className={`${classes['search-donor__item']} ${props.active && classes.active}`}
            >
                <p className={classes['search-donor__name']}>{props.option.name}</p>
                <p className={classes['search-donor__id']}>ID: {props.option.value}</p>
                <div className={classes['search-donor__phone']}><div><p>{phoneMobile}</p><p>{phoneHome}</p></div><p className={classes['devider']}>{(phoneHome || phoneMobile) && props.option.email && '|'}</p><p>{props.option.email}</p></div>
                <p className={classes['search-donor__address']}>{address}</p>
            </li>
            <hr className={classes['search-donor__hr']} />
        </Fragment>
    )
})

export default DonorSearchItem