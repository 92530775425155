import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    campaigns: [],
    subCampaigns: [],
    isLoading: false,
}
const campaignsSlice = createSlice({
    name: "campaigns",
    initialState,
    reducers: {
        loadCampaigns(state, action) {
            state.campaigns = action.payload.campaigns;
        },
        loadSubCampaigns(state, action) {
            state.subCampaigns = action.payload.subCampaigns;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        resetState:() => initialState,
    },
});

export const campaignsActions = campaignsSlice.actions;

export default campaignsSlice;
