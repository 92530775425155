import { settingsActions } from './settings-slice'
import store from './index'

export const fetchDefaultAmounts = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + 'api/websettings',{
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                throw new Error('Could not fetch default amounts.')
            }
            const data = await response.json()
            return data;
        };

        try {
            const settingsData = await fetchData()
            dispatch(settingsActions.setDefaultAmounts(settingsData))

        } catch (error) {
           // dispatch(uiActions.showErrorMessage(error.message))
        }
    };
};

