import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uiActions } from '../../../store/ui-slice'
import SidePopup from './SidePopup'
import classes from './SidePopupMessage.module.css'

const SidePopupMessage = () => {

    const sidePopup = useSelector((state) => state.ui.sidePopup)
    const dispatch = useDispatch()

    const [showPopup, setShowPopup] = useState()

    useEffect(()=>{
        if(sidePopup){
            setShowPopup(true)
            setTimeout(() => {
                dispatch(uiActions.showSidePopup(null))
            }, 4500);
        }
        else{
            setShowPopup(false)
        }
    },[sidePopup])

   if(sidePopup){
    return <SidePopup status={sidePopup.status} header={sidePopup.header} message={sidePopup.message}/>
   }
   else{
    return null
   }
}

export default SidePopupMessage