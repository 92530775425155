
import LoadingSpinnerButtonLight from '../../LoadingSpinners/LoadingSpinnerButtonLight'
import classes from './ButtonMOut.module.css'
import ButtonOut from './ButtonOut'

const ButtonMOut = (props) => {
    return (
        <ButtonOut className={`${classes['btn-m']}  ${props.className}`} type={props.type ? props.type : 'button'} onClick={props.onClick} disabled={props.disabled}>
            {props.children}
            {props.loading && <LoadingSpinnerButtonLight/>}
        </ButtonOut>
    )
}

export default ButtonMOut