import { Fragment, useEffect, useState } from "react"

import classes from './ImageUpload.module.css'

const ImageUpload = (props) => {
    const [preview, setPreview] = useState()

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!props.selectedFiles) {
            setPreview(undefined)
            return
        }
        var objects = props.selectedFiles.map(f => {
            const objectUrl = URL.createObjectURL(f)
            return objectUrl
            
        })
        setPreview(objects)

        // free memory when ever this component is unmounted
        return () => {
            objects.map(o => URL.revokeObjectURL(o))
            }
    }, [props.selectedFiles])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            props.onChange([])
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        const files = [...props.selectedFiles,  e.target.files[0]]
        props.onChange(files)
        
    }

    return (
        <Fragment>
        <div className={`${classes['upload-file']} ${props.className}`}>
            <label htmlFor="file-upload" className={classes["custom-file-upload"]}>
                    {props.title}
                </label>
            <input type='file' id="file-upload"  onChange={onSelectFile} multiple/>
        </div>
        {props.selectedFiles && props.selectedFiles.length > 0 && preview && preview.length > 0 && <div className={classes['uploaded-files']}>{props.selectedFiles.map((f, i) => 
        <div className={classes['uploaded-file']}>
            <img src={preview[i]} alt=''/>
            <span>{f.name}</span>
            </div>)}
            </div>}
        </Fragment>
    )
}

export default ImageUpload