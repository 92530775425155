import { useEffect, useState } from "react"
import useHttp from "../../../../hooks/use-http";
import classes from './ChargeTypes.module.css'

const ChargeTypes = (props) => {
    const {sendRequest, isLoading} = useHttp()

    const [chargeTypes, setChargeTypes] = useState([])

    useEffect(()=>{
        fetchChargeTypes()
    },[])
    
    const fetchChargeTypes = () => {
        sendRequest({url:`api/chargeTypes?recurring=${props.recurring ? true : false}`}, handleResponse)
    } 

    const handleResponse = (data) => {
        setChargeTypes(data)
        console.log(data)
    }

    return (
        <div className={`${classes['popup-list']} ${props.details && classes.left}`}>
            <p key={0} className={classes['popup-item']} id={`${props.recurring ? 'addRecCharge': 'addCharge'} ${props.dashboardId} ${props.taskId} ${0}`} onClick={props.onClose}>Default Charge</p>
            {chargeTypes.map(t => {
                return <p key={t.ChargeID} 
                className={classes['popup-item']} 
                id={`${props.recurring ? 'addRecCharge': 'addCharge'} ${props.dashboardId} ${props.taskId} ${t.ChargeID}`}
                onClick={(e) => {e.stopPropagation(); props.onClose();}}>
                    {t.Name} - {t.Amount}
                </p>
            })}
        </div>
    )

}

export default ChargeTypes