import { useEffect, Fragment,useRef } from "react"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { useReactToPrint } from 'react-to-print';

import { fetchStatementsList } from "../../../store/statements-actions"
import MainHeader from "../../layout/MainHeader"
import ButtonL from "../../UI/Buttons/Buttons/ButtonL"
import classes from './Statement.module.css'
import StatementView from "./StatementView"
import Phone from '../../../images/Call.svg'
import Location from '../../../images/Location.svg'
import Message from '../../../images/Message.svg'
import ButtonLOut from "../../UI/Buttons/Buttons-Outline/ButtonLOut"
import printer from '../../../images/printer.svg'


const Statement = () => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()


    useEffect(()=>{
        dispatch(fetchStatementsList())
    },[dispatch])


    return (
        <Fragment>
            <MainHeader title='Statement'/>
               <div className={classes.headers}>
               <h2 className="h2">Statement for <br className={classes['statement-br']}/> School Year {localStorage.getItem("SchoolYear")}</h2>
                </div> 
                <div className={classes.statement}>
                <StatementView ref={componentRef}/>
                <div className={classes['right-side']}>
                    <div className={classes['school-details']}>
                        <div><h4>{localStorage.getItem('sessionCompanyName')}</h4></div>
                        {localStorage.getItem('SchoolAddress') && <div><img src={Location} alt=''/><p>{localStorage.getItem("SchoolAddress")}</p></div>}
                        {localStorage.getItem('SchoolPhone') && <div><img src={Phone} alt=''/><p>{localStorage.getItem("SchoolPhone")}</p></div>}
                        {localStorage.getItem('SchoolEmail') && <div><img src={Message} alt=''/><a href={`mailto:${localStorage.getItem("SchoolEmail")}`} target="_blank" rel="noopener noreferrer">{localStorage.getItem("SchoolEmail")}</a></div>}
                    </div>
                    <div className={classes['buttons-right-side']}>
                        <ButtonLOut loading={false} onClick={handlePrint}>Print Statement<img src={printer} alt=''/></ButtonLOut>
                        <ButtonL className={classes['add-payment']} onClick={()=>{ navigate(`/${params.organization}/${params.school}/parents/statement/new-payment`)}}>Add Payment</ButtonL>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Statement