import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import Badge from "../../../UI/Others/Badge"
import ListItem from "../../../UI/Others/ListItem"
import classes from './FeaturesList.module.css'
import ArrowUp from '../../../../images/Arrow - Up Circle.svg'
import ArrowDown from '../../../../images/Arrow - Down Circle.svg'
import On from '../../../../images/Show.svg'
import Off from '../../../../images/Hide.svg'
import useHttp from "../../../../hooks/use-http"

const Feature = (props) => {

    const [votes, setVotes] = useState()
    const [myVotes, setMyVotes] = useState()
    const [notificationsSrc, setNotificationsSrc] = useState(Off)
    const navigate = useNavigate()
    const {isLoading, sendRequest: saveVote} = useHttp()
    const {isLoading: notificationsLoading, sendRequest: turnNotificationsOnandOff} = useHttp()

    useEffect(() => {
        if(props.feature){
            setVotes(props.feature.Votes)
            setMyVotes(props.feature.MyVotes)
            if(props.feature.IsWatching){
                setNotificationsSrc(On)
            }
        }
    },[props.feature])

    const viewDetailsHandler = () => {
        navigate(`/new-features/feature-details/${props.feature.FeatureID}`)
    }

    const voteUpHandler = () => {
        if(myVotes >2){
            return;
        }
        voteForFeature(true)
    }

    const voteDownHandler = () => {
        if(myVotes === 0){
            return;
        }
        voteForFeature(false)
    }

    const voteForFeature = (voteUp) => {
        saveVote({url: `/api/vote-feature?featureId=${props.feature.FeatureID}&voteUp=${voteUp}&summary=${props.feature.Summary}`}, (data) => handleSaveResponse(data, voteUp))
    }

    const handleSaveResponse = (data, voteUp) => {
        if(data){
            if(voteUp){
                setVotes(prevState => parseInt(prevState) + 1)
                setMyVotes(prevState => parseInt(prevState) + 1)
                if(notificationsSrc === Off){
                    notificationsOnAndOffHandler()
                }
                props.onChangedVotes(props.feature.FeatureID, parseInt(votes) + 1, parseInt(myVotes) + 1)
            }
            else{
                setVotes(prevState => parseInt(prevState) - 1)
                setMyVotes(prevState => parseInt(prevState) - 1)
                props.onChangedVotes(props.feature.FeatureID, parseInt(votes) - 1, parseInt(myVotes) - 1)
            }

        }
    }

    const notificationsOnAndOffHandler = () => {
        turnNotificationsOnandOff({url: `api/turn-notifications-on-and-off?featureId=${props.feature.FeatureID}&add=${notificationsSrc === Off}`}, handleNotificationResponse)
    }

    const handleNotificationResponse = (data) => {
        if(data){
            if(notificationsSrc === On){
                setNotificationsSrc(Off)
            }
            else{
                setNotificationsSrc(On)
            }
        }
    }

    return(
        <ListItem className={classes.feature}>
        <p className={classes.id}>{props.feature.FeatureID}</p>
        <p className={classes.title}>{props.feature.Summary}</p>
        <p className={classes.voted}>{votes}</p>
        <p className={classes['my-votes']}><img src={ArrowUp} className={`${classes.arrow} ${isLoading ? classes.loading : ''}`} alt='' onClick={voteUpHandler}/>{myVotes}<img className={`${classes.arrow} ${isLoading ? classes.loading : ''}`} src={ArrowDown} alt='' onClick={voteDownHandler}/></p>
        <p className={classes.completion}>{props.feature.CompletionDate}</p>
        <p className={classes.details} onClick={viewDetailsHandler}>View Details</p>
        <p className={classes.watch}><img className={ notificationsLoading ? classes.loading : ''} src={notificationsSrc} alt='' onClick={notificationsOnAndOffHandler}/></p>
        <div className={classes['status-container']}>
        {(props.feature.Status === 'In Progress' || props.feature.Status === 'Pending' || props.feature.Status === 'To be Updated')&& <Badge status={'In Progress'} className={classes.status}>In Progress</Badge>}
        </div>
        </ListItem>

    )
}

export default Feature