const filterObjectArray = (oldArray, string) => {
  const stringArray = string.split(" ");
  var newArray = oldArray;
  (stringArray).forEach((s) => {
    newArray = newArray.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(s.toLowerCase())
      )
    );
  });
  return newArray;
};

export default filterObjectArray;
