import { Fragment } from "react"

import classes from './LoginModal.module.css'
import LogoRight from '../../../images/Logo-login-right.svg'
import LogoLeft from '../../../images/Logo-login-left.svg'
import SubmitButton from "../Buttons/Buttons/SubmitButton"



const LoginModal = (props) => {

    return (
        <Fragment>
            <div className={classes['login-wrapper']}>
                <div className={classes['login-background']}>
                    <img src={LogoLeft} alt=''/>
                    <p>Non-profit data, streamlined</p>
                </div>
                <div className={classes['login-right']}>
                    <img className={classes['logo-right']} src={props.img ? props.img : LogoRight} alt=''/>
                    <form className={classes['login-form']}>
                        <h1>{props.name} <br/><span className={classes['login-title']}>{props.title}</span></h1>
                        {props.children}
                        <SubmitButton submit onClick={props.onSubmit} loading={props.isLoading}>{props.title}</SubmitButton>
                        {props.underButton}
                    </form>
                </div>
            </div>
        </Fragment>
    )

}

export default LoginModal