
import classes from './AddTask.module.css'
import ModelNew from '../../../UI/Modals/ModalNew'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import CustomSelect from '../../../UI/Dropdowns/CustomSelect'
import TextField from '../../../UI/Inputs/TextField'
import DateInput from '../../../UI/Inputs/DateInput'
import ButtonMOut from '../../../UI/Buttons/Buttons-Outline/ButtonMOut'
import ButtonM from '../../../UI/Buttons/Buttons/ButtonM'
import { useEffect, useState } from 'react'
import CurrentDateForDateInput from '../../../../functions/Dates/datesForDateInput'
import CustomSelectFreeText from '../../../UI/Dropdowns/CustomSelectFreeText'
import DonorSearchBox from '../../../UI/SearchBoxs/DonorSearchBox/DonorSearchBox'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllDashboardTasksAndTotals, fetchListForNewTask, fetchReminderTypes } from '../../../../store/hub-actions'
import PinkTextButton from '../../../UI/Buttons/TextButtons/PinkTextButton'
import { converDateForBackendWithCurrentTime } from '../../../../functions/Dates/ConvertDateForC#'
import useHttp from '../../../../hooks/use-http'
import { uiActions } from '../../../../store/ui-slice'
import Input from '../../../UI/Inputs/Input'
import { getAddMessageBody } from '../../../../functions/getMessageBody'

const AddTask = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {sendRequest: saveTask, isLoading} = useHttp()
    const [searchParams] = useSearchParams()

    const types = useSelector((state) => state.hub.reminderTypes)
    const list = useSelector((state) => state.hub.listForNewTask)
    const dashboards = useSelector((state) => state.hub.dashboards)

    const [listObj, setListObj] = useState([])
    const [type, setType] = useState('')
    const [names, setNames] = useState([])
    const [dashboardId, setDashboardId] = useState('')
    const [dashboardName, setDashboardName] = useState('')
    const [selectedObj, setSelectedObj] = useState(null)
    const [selecteId, setSelectedId] = useState()
    const [selectedIdInvalid, setSelectedIdInvalid] = useState()
    const [description, setDescription] = useState('')
    const [dueDate, setDueDate] = useState(CurrentDateForDateInput())

    useEffect(()=>{
        if(types.length === 0){
            dispatch(fetchReminderTypes())
        }
    },[])

    useEffect(()=>{
        if(dashboardId){
            dispatch(fetchListForNewTask(dashboardId))
            setSelectedObj(null)
        }
    },[dashboardId])

    useEffect(()=>{
        let id
        if(searchParams.get('id')){
            id = parseInt(searchParams.get('id'))
        }

        if(dashboards.length > 0){
            let n = dashboards.map(d => {
                return {value: d.DashboardSettingID, name: d.Name}
            })
            setNames(n)    
            if(id){
                let name = n.find(a => a.value === id)
                if(name){
                    setDashboardId(name.value)
                    setDashboardName(name.name)
                }
            }
            else{
                setDashboardId(n[0].value)
            }
        }
    },[dashboards, searchParams])

    useEffect(() => {
        if(list){
            let lst
            if(list.Donors){
                lst = list.Donors.map(donor => {
                    return {value: donor.DonorsID, type: 'Donor', name: donor.FirstName + " " + donor.LastName, phoneHome: donor.PhoneHome, phoneMobile: donor.PhoneMobile, email: donor.Email, address: {Address: donor.Address, City:  donor.City, State: donor.State, Zip: donor.Zip}}
                })
            } 
            else if(list.Parents){
                lst = list.Parents.map(parent => {
                    return {value: parent.DonorsID, type: 'Parent', name: parent.FirstName + " " + parent.LastName, phoneHome: parent.PhoneHome, phoneMobile: parent.PhoneMobile, email: parent.Email, address: {Address: parent.Address, City:  parent.City, State: parent.State, Zip: parent.Zip}}
                })
            }
            else if(list.Students){
                lst = list.Students.map(student => {
                    return {value: student.DonorsID, type: 'Student', name: student.FirstName + " " + student.LastName, phoneHome: student.PhoneHome, phoneMobile: student.PhoneMobile, email: student.Email, address: {Address: student.Address, City:  student.City, State: student.State, Zip: student.Zip}}
                })
            }
            setListObj(lst)
        }
    },[list])



    const closeNewTaskHandler = () => {
        navigate('..')
    }

    const changeTypeHandler = (value) => {
        setType(value)
    }

    const changeNameHandler = (id) => {
        let name = names.find(n => n.value === id)
        setDashboardId(name.value)
    }

    const changeObjHandler = (id) => {
        setSelectedId(id)
        setSelectedIdInvalid(false)
        const obj = listObj.find(p => p.value === id)
        if(obj){
            setSelectedObj(obj)
        }
        else{
            setSelectedObj(null)
        }
    }

    const saveTaskHandler = () => {
        if(!selecteId){
            setSelectedIdInvalid(true)
            return
        }
        let n = names.find(a => a.value === dashboardId)
        const task = {
            ID: selecteId,
            Type: type,
            Description: description,
            Name: n ? n.name : '',
            Date: converDateForBackendWithCurrentTime(dueDate)
        }
        saveTask({url: 'api/saveTask', method: 'POST', body: task, headers: {
            Accept: "application/json",
            "content-type": "application/json",
          },}, handleTaskResponse)
    }

    const handleTaskResponse = (data) => {
        if(data.ActionSuccessful){
            dispatch(uiActions.showSidePopup({status: 'success', header: getAddMessageBody('task')}))
            dispatch(fetchAllDashboardTasksAndTotals())
            navigate(-1)
        }
        else{
            dispatch(uiActions.showSidePopup({status: 'error', header: getAddMessageBody('task', false), message: data.ActionResultMessage}))
        }
    }

    return (
        <ModelNew title='Task' onClose={closeNewTaskHandler} className={classes.white} modelClassName={classes.model} backdropClassName={classes.backdrop}>
            <CustomSelectFreeText selectedValue={type} options={types} label='Type' onChange={changeTypeHandler} />
            <div className={classes.dropdowns}>
                {!searchParams.get('id') && <CustomSelect options={names} label='Name' onChange={changeNameHandler} className={classes.name} selectedValue={dashboardId}/>}
                {searchParams.get('id') && <Input label='Name' readOnly value={dashboardName}  className={classes.name} />}
                <DateInput label='Due Date' value={dueDate} onChange={(v) => setDueDate(v)} className={classes.date}/>
            </div>
            <div>
            {selectedIdInvalid && listObj.length > 0 && <p className={classes.invalid}>Select {listObj[0].type}</p>}
            {!selectedObj && listObj.length > 0 && <DonorSearchBox list={listObj} placeholder={`Search ${listObj[0].type}`} onSelect={changeObjHandler} className={classes['donor-search']}/>}
            {selectedObj && <div className={classes['parent-wrapper']}>
                <p className={classes['parent-label']}>{selectedObj.type}</p>
                <div className={classes['connected-parent']}>
                <p className={classes.parentid}>{selectedObj.value}</p>
                <p className={classes['parent-name']}>{selectedObj.name}</p>
                <PinkTextButton className={classes['remove-parent']} onClick={() => changeObjHandler(null)}>Remove</PinkTextButton>
                </div></div>}
            </div>
            <TextField label='Description' value={description} onChange={(v) => setDescription(v)}/>
            <div className={classes.buttons}>
                <ButtonMOut onClick={closeNewTaskHandler}>Cancel</ButtonMOut>
                <ButtonM onClick={saveTaskHandler} loading={isLoading}>Save</ButtonM>
            </div>
        </ModelNew>
    )
}

export default AddTask