import React, { useEffect, Suspense, useState } from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import { useIdleTimer } from 'react-idle-timer';
import Hotjar from '@hotjar/browser';



import Notification from './components/UI/MessageBoxs/Notification';
import LoadingSpinner from './components/UI/LoadingSpinners/LoadingSpinner';
import ErrorMessage from './components/UI/MessageBoxs/ErrorMessage';
import { fetchPaymentTypesList } from './store/paymentTypes-actions';
import { fetchCampaignsList, fetchSubCampaignsList } from './store/campaigns-actions'
import { fetchOrganizationsList } from './store/organizations-actions'
import { fetchDonorsList } from './store/donors-actions'
import { fetchPledgesList, fetchRecurringPledgesList } from './store/pledges-actions';
import { fetchSolicitorsList } from './store/solicitors-actions'
import { fetchAccountsList } from './store/accounts-actions';
import { fetchShulsList } from './store/shuls-actions';
import { fetchDefaultAmounts } from './store/settings-actions';
import { fetchRecurringPaymentsList } from './store/payments-actions';
import NewFeatures from './pages/NewFeatures/NewFeatures';
import SignUpPortalUser from './pages/Login/SignUpPortalUser';
import NewFeatureDetails from './pages/NewFeatures/NewFeatureDetails';
import AddNewFeature from './pages/NewFeatures/AddNewFeature';
import useResetState from './hooks/use-reset';
import { fetchStudentsList } from './store/students-actions';
import PageNotFound from './pages/PageNotFound';
import Statement from './pages/ParentLogin/Statement/Statement'
import Tasks from './pages/Hub/Tasks';
import TasksApplication from './pages/Hub/TasksApplication';
import LoginApplication from './pages/Login/LoginApplication';
import NewTask from './pages/Hub/NewTask';
import SidePopupMessage from './components/UI/MessageBoxs/SidePopupMessage';
import { Fragment } from 'react';
import TaskDetailsPage from './pages/Hub/TaskDetailsPage';

const Login = React.lazy(() => import('./pages/Login/Login'))
const Payments = React.lazy(() => import('./pages/Payments/Payments'))
const NewPayment = React.lazy(() => import('./pages/Payments/NewPayment'))
const PaymentDetail = React.lazy(() => import('./pages/Payments/PaymentDetail'))
const RecurringPayments  = React.lazy(() => import('./pages/Payments/RecurringPayments/RecurringPayments'));
const RecurringPaymentDetail = React.lazy(() => import('./pages/Payments/RecurringPayments/RecurringPaymentDetail'))
const Pledges = React.lazy(() => import('./pages/Pledges/Pledges')) ;
const NewPledge = React.lazy(() => import('./pages/Pledges/NewPledge'))
const PledgeDetail = React.lazy(() => import('./pages/Pledges/PledgeDetail'))
const RecurringPledges = React.lazy(() => import('./pages/Pledges/RecurringPledges/RecurringPledges'))
const RecurringPledgeDetail = React.lazy(() => import('./pages/Pledges/RecurringPledges/RecurringPledgeDetail'))
const Donors = React.lazy(() => import('./pages/Donors/Donors'))
const NewDonor = React.lazy(() => import('./pages/Donors/NewDonor'))
const DonorDetail = React.lazy(() => import('./pages/Donors/DonorDetail'))
const DonorEdit = React.lazy(() => import('./pages/Donors/DonorEdit'))
const Settings = React.lazy(() => import('./pages/Settings'))
const PaymentLinkPage = React.lazy(() => import('./pages/Payments/PaymentLink'))
const SignUp = React.lazy(() => import('./pages/ParentLogin/Login/SignUp'))
const ParentLogin = React.lazy(() => import('./pages/ParentLogin/Login/ParentLogin'))
//const Statement = React.lazy(() => import('./pages/ParentLogin/Statement/Statement'))
const ParentPaymentDetails = React.lazy(() => import('./pages/ParentLogin/PaymentDetails'))
const ParentChargeDetails = React.lazy(() => import('./pages/ParentLogin/ChargeDetails'))
const AddParentPayment = React.lazy(() => import('./pages/ParentLogin/AddPayment'))
const Registartion = React.lazy(() => import('./pages/ParentLogin/Registration/Registration'))
const Students = React.lazy(() => import('./pages/ParentLogin/Students/Students'))
const StudentDetails = React.lazy(() => import('./pages/ParentLogin/Students/StudentDetails'))


const App = () => {

  //#region hooks
  const dispatch = useDispatch()
  const { resetState } = useResetState()
  const params = useParams()

  const campaigns = useSelector((state) => state.campaigns.campaigns)
  const subCampaigns = useSelector((state) => state.campaigns.subCampaigns)
  const organizations = useSelector((state) => state.organizations.organizations)
  const donors = useSelector((state) => state.donors.donors)
  const pledges = useSelector((state) => state.pledges.pledges)
  const recurringPayments = useSelector((state) => state.payments.recurringPayments)
  const recurringPledges = useSelector((state) => state.pledges.recurringPledges)
  const loadedPledges = useSelector((state) => state.pledges.loadedPledges)
  const loadedRecurringPayments = useSelector((state) => state.payments.loadedRecurringPayments)
  const loadedRecurringPledges = useSelector((state) => state.pledges.loadedRecurringPledges)
  const solicitors = useSelector((state) => state.solicitors.solicitors)
  const paymentTypes = useSelector((state) => state.paymentTypes.paymentTypes)
  const accounts = useSelector((state) => state.accounts.accounts)
  const shuls = useSelector((state) => state.shuls.shuls)
  const notification = useSelector((state) => state.ui.notification)
  const errorMessage = useSelector((state) => state.ui.errorMessage)
  const loggedIn = useSelector((state) => state.auth.isLoggedIn)
  const parentLoggedIn = useSelector((state) => state.auth.parentLoggedIn)
  const portalUserLoggedIn = useSelector((state) => state.auth.portalUserLoggedIn)
  const hubUserLoggedIn = useSelector((state) => state.auth.hubUserLoggedIn)
  const defaultAmounts = useSelector((state) => state.settings.defaultAmounts)
  const loadedStudents = useSelector((state) => state.students.loadedStudents)
  const allowHub = useSelector((state) => state.auth.hub)
  

  const [trialExpiration, setTrialExpiration] = useState(null)
  const [returnComponent, setReturnComponent] = useState(<Fragment></Fragment>)
  //#endregion

  //#region use effects to load up all the lists

  useEffect(() => {
    if (localStorage.getItem('inTrial') && localStorage.getItem('inTrial') === 'True') {
      var now = new Date()
      var endDate = new Date(localStorage.getItem('trialEndDate'))
      const utc1 = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
      const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

      var days = Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
      setTrialExpiration(days)
    }
    else {
      setTrialExpiration(null)
    }
  }, [loggedIn])

  useEffect(()=>{
    const siteId = 3737662;
    const hotjarVersion = 6;

    Hotjar.init(siteId, hotjarVersion);
  },[])



  useEffect(() => {
    if (campaigns.length === 0 && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchCampaignsList())
    }
  }, [dispatch, campaigns.length, loggedIn])
  useEffect(() => {
    if (subCampaigns.length === 0 && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchSubCampaignsList())
    }
  }, [dispatch, subCampaigns.length, loggedIn])
  useEffect(() => {
    if (organizations.length === 0 && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchOrganizationsList())
    }
  }, [dispatch, organizations.length, loggedIn])
  useEffect(() => {
    if (donors.length === 0 && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchDonorsList())
    }
  }, [dispatch, donors.length, loggedIn])
  useEffect(() => {
    if (pledges.length === 0 && !loadedPledges && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchPledgesList(50))
    }
  }, [dispatch, pledges.length, loggedIn, loadedPledges])
  useEffect(() => {
    if (recurringPayments.length === 0 && !loadedRecurringPayments && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchRecurringPaymentsList(50))
    }
  }, [dispatch, recurringPayments.length, loggedIn, loadedRecurringPayments])
  useEffect(() => {
    if (recurringPledges.length === 0 && !loadedRecurringPledges && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchRecurringPledgesList(50))
    }
  }, [dispatch, recurringPledges.length, loggedIn, loadedRecurringPledges])
  useEffect(() => {
    if (solicitors.length === 0 && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchSolicitorsList())
    }
  }, [dispatch, solicitors.length, loggedIn])
  useEffect(() => {
    if (paymentTypes.length === 0 && (loggedIn || parentLoggedIn) && ! window.location.href.includes('-app')) {
      dispatch(fetchPaymentTypesList())
    }
  }, [dispatch, paymentTypes.length, loggedIn, parentLoggedIn])
  useEffect(() => {
    if (accounts.length === 0 && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchAccountsList())
    }
  }, [dispatch, accounts.length, loggedIn])
  useEffect(() => {
    if (shuls.length === 0 && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchShulsList())
    }
  }, [dispatch, shuls.length, loggedIn])
  useEffect(()=>{
    if(!defaultAmounts && (loggedIn) && ! window.location.href.includes('-app')){
      dispatch(fetchDefaultAmounts())
    }
  },[dispatch, defaultAmounts, loggedIn])
  useEffect(()=>{
    if(parentLoggedIn && !loadedStudents && ! window.location.href.includes('-app')){
      dispatch(fetchStudentsList())
    }
  },[parentLoggedIn, loadedStudents, dispatch])
  //#endregion

  
  const handleOnIdle = () => {
    logoutFromAll()
  }

  const logoutFromAll = () => {
    resetState()
  }


  useIdleTimer({
    timeout: 1800000,
    onIdle: handleOnIdle,
    debounce: 500
  })

useEffect(()=>{
  const component = <Routes>
      <Route path='/' element={<Navigate to="/login"/>}/>
      <Route path="*" element={<PageNotFound/>} />
      <Route path='/paymentslist' element={loggedIn ? <Payments /> : <Navigate to="/login" replace />}>
        <Route path='new-payment' element={loggedIn ? <NewPayment /> : <Navigate to='/login' />} />
        <Route path='payment-details' element={loggedIn ? <PaymentDetail /> : <Navigate to='/login' replace />} />
      </Route>
      <Route path='/rec-payments-list' element={loggedIn ? <RecurringPayments/> : <Navigate to='/login' replace/>}>
        <Route path='new-payment' element={loggedIn ? <NewPayment /> : <Navigate to='/login' />} />
        <Route path='payment-details' element={loggedIn ? <RecurringPaymentDetail/> : <Navigate to='/login' replace/>}/>
      </Route>
      <Route path='/pledges-list' element={loggedIn ? <Pledges /> : <Navigate to="/login" replace />}>
        <Route path='new-pledge' element={loggedIn ? <NewPledge /> : <Navigate to='/login' />} />
        <Route path='pledge-details' element={loggedIn ? <PledgeDetail /> : <Navigate to='/login' replace />} />
      </Route>
      <Route path='/rec-pledges-list' element={loggedIn ? <RecurringPledges/> : <Navigate to='/login' replace/>}>
        <Route path='new-pledge' element={loggedIn ? <NewPledge /> : <Navigate to='/login' />} />
        <Route path='pledge-details' element={loggedIn ? <RecurringPledgeDetail/> : <Navigate to='/login' replace/>}/>
        </Route>  
      <Route path='/donors-list' element={loggedIn ? <Donors /> : <Navigate to="/login" replace />}>
        <Route path='new-donor' element={loggedIn ? <NewDonor /> : <Navigate to='/login' />} />
        <Route path='donor-details' element={loggedIn ? <DonorDetail /> : <Navigate to='/login' replace />} />
        <Route path='donor-edit' element={loggedIn ? <DonorEdit /> : <Navigate to='/login' replace />} />
      </Route>
      <Route path='/settings' element={loggedIn ? <Settings /> : <Navigate to="/login" replace />} />
      <Route path='/login' element={<Login />} />
      {/*payment link*/}
      <Route path='/payments-online/:organization/:subdomain' element={<PaymentLinkPage/>}/>
      {/*portal user pages*/}
      <Route path='/portal-user-signup' element={<SignUpPortalUser/>}/>
      <Route path='/new-features' element={loggedIn || portalUserLoggedIn ? <NewFeatures/> : <Navigate to='/login' replace/>}>
        <Route path='feature-details/:id' element={loggedIn || portalUserLoggedIn ? <NewFeatureDetails/> : <Navigate to='/login' replace/>}/>
        <Route path='suggest-feature' element={loggedIn || portalUserLoggedIn ? <AddNewFeature/> : <Navigate to='/login' replace/>}/>
     </Route>
      {/*parent login pages*/}
      <Route path='/:organization/:school/parents/signup' element={<SignUp/>}/>
      <Route path='/:organization/:school/parents/login' element={<ParentLogin/>}/>
      <Route path='/:organization/:school/parents/statement' element={<Statement/>}>
        <Route path='payment-details' element={<ParentPaymentDetails/>}/>
        <Route path='charge-details' element={<ParentChargeDetails/>}/>
        <Route path='new-payment' element={<AddParentPayment/>}/>
      </Route>
      <Route path='/:organization/:school/parents/students' element={<Students/>}>
        <Route path='student-details' element={<StudentDetails/>}/>
      </Route>
      <Route path='/:organization/:school/parents/registration' element={<Registartion/>}/>
      <Route path='/hub' element={loggedIn && allowHub ? <Tasks/> : <Navigate to='/login' replace/>}>
        <Route path='task-details' element={loggedIn && allowHub ? <TaskDetailsPage/> : <Navigate to='/login' replace/>} />
        <Route path='new-task' element={loggedIn && allowHub ? <NewTask/> : <Navigate to='/login' replace/>} />
        </Route>
      <Route path='/hub-app' element={(loggedIn && allowHub) || hubUserLoggedIn ? <TasksApplication/> : <Navigate to='/login-app' replace/>}>
        <Route path='task-details' element={(loggedIn && allowHub) || hubUserLoggedIn ? <TaskDetailsPage/> : <Navigate to='/login-app' replace/>}/>
        <Route path='new-task' element={(loggedIn && allowHub) || hubUserLoggedIn ? <NewTask/> : <Navigate to='/login-app' replace/>} />
        </Route>
      <Route path='/login-app' element={<LoginApplication/>}/>
    </Routes>
setReturnComponent(component)
},[loggedIn, hubUserLoggedIn, portalUserLoggedIn, trialExpiration, errorMessage, notification, allowHub])
  return (
    <React.Fragment>
      <label className='development-mode'>Beta Version</label>
      {trialExpiration !== null && <p className='trial'>{trialExpiration === 1 ? '1 day left on trial!' : trialExpiration === 0 ? 'Last day on trial!' : trialExpiration + ' days left on trial!'}<br /><a className='trial-a' href='https://advanceddata.org/contact-us/' target='_blank' rel='nooepener noreferrer'>Contact sales</a> now to activate!</p>}
      {errorMessage && <ErrorMessage />}
      {notification && <Notification message={{ message: notification.message, status: notification.status, title: notification.title }} onClose={() => window.location.reload()} secondButton={notification.secondButton} onSecondButtonClick={notification.onSecondButtonClick} />}
      <SidePopupMessage/>
      <Suspense fallback={<LoadingSpinner />}>
        {returnComponent}
      </Suspense>
    </React.Fragment>
  )
}

export default App;
