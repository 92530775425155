import { toDecimalWithCommas } from "./toDecimal";

const formatFieldByFieldName = (fieldName, value) => {
    //we have the same function in C# - commonUtility - need to update there with any update here
    let val = value;
    if(["amount", "value", "balance" ].some(v => fieldName.toLowerCase().includes(v)) && !isNaN(value))
    {
        val = "$" + toDecimalWithCommas(value);
    }
    else if (["time"].some(v => fieldName.toLowerCase().includes(v)))
    {
        const date = new Date(value);
        if(date !== 'Invalid Date' && !isNaN(date)){
            const yyyy = date.getFullYear();
            let mm = date.getMonth() + 1; // Months start at 0!
            let dd = date.getDate();
            let hh = date.getHours();
            let m = date.getMinutes();


            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;
            if (hh < 10) hh = '0' + hh;
            if (m < 10) m = '0' + m;

            val = mm + '/' + dd + '/' + yyyy + ' ' + hh + ':' + m;
        }    
    }
    else if(["date"].some(v => fieldName.toLowerCase().includes(v)))
    {
        const date = new Date(value);
        if(date !== 'Invalid Date' && !isNaN(date)){
            const yyyy = date.getFullYear();
            let mm = date.getMonth() + 1; // Months start at 0!
            let dd = date.getDate();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            val = mm + '/' + dd + '/' + yyyy;
        }
    }
    


    return val;

}

export default formatFieldByFieldName