
import Bag from '../../images/Bag 2.svg'
import Logout from '../../images/Logout.svg'
import classes from './UserDetails.module.css'
import Popup from '../UI/Others/Popup'
import useResetState from '../../hooks/use-reset'


const UserDetails = () => {
  const { resetState } = useResetState()

  const logoutHandler = () => {
    resetState()
  }

    return (
        <Popup className={classes['user-details']} onClick={(e) => e.stopPropagation()}>
            <h4 className={classes['user-details__header']}>{localStorage.getItem('sessionUserName')}</h4>
            <ul>
              {/*<li className={classes['user-detail']}><img src={Message} className={classes['user-detail__img']} alt=''/>{localStorage.getItem('userName')}</li>*/}
              <li className={classes['user-detail']}><img src={Bag} className={classes['user-detail__img']} alt=''/>{localStorage.getItem('sessionCompanyName')}</li>
              <div className={classes['user-detail__separator']}></div>
              <li className={`${classes['user-detail']} ${classes['logout']}`} onClick={logoutHandler}><img src={Logout} className={classes['user-detail__img']} alt=''/>Log Out</li>
            </ul>
            </Popup>
    )
}

export default UserDetails