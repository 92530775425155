
import { useEffect } from 'react'
import { useState } from 'react'
import Badge from '../../UI/Others/Badge'
import classes from './StatementViewItem.module.css'

const StatementViewItem = (props) => {

    //#region hooks

    const [date, setDate] = useState()
    const [value, setValue]= useState()
    const [balance, setBalance] = useState()

    useEffect(() => {
        if (props.statement.Date) {
            var date = new Date(props.statement.Date)
            var formattedDate = date.toLocaleString(undefined, { year: "numeric", month: "2-digit", day: "2-digit"}).replace(',', '')
            setDate(formattedDate)
        }

    }, [props.statement.Date])

    useEffect(() => {
        var amount = parseFloat(props.statement.Value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        setValue(amount)
    }, [props.statement.Value])

    useEffect(() => {
        var amount = parseFloat(props.statement.Balance).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        setBalance(amount)
    }, [props.statement.Balance])

    //#endregion

    return (
        <div className={classes['view-item']}>
            <p className={classes.value}>${value}</p>
            <p className={classes.balance}>${balance}</p>
            <p className={classes.date}>{date}</p>
            <Badge className={classes.type} status={props.statement.Status ? props.statement.Status : 'Charge'}>{props.statement.Status && props.statement.Status !== 'Successful' && props.statement.Status}{props.statement.PaymentID > 0 ? 'Payment' : 'Charge'}</Badge>
            <p className={classes.details}>{props.statement.PaymentID > 0 ? props.statement.PaymentType : props.statement.SchoolType}</p>
            <p className={classes.student}>{props.statement.StudentName}</p>
            <p className={classes['view-details']} onClick={props.onSelect}>View Details</p>
        </div>
    )
}

export default StatementViewItem