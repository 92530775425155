import { Fragment } from 'react'
import ReactDom from 'react-dom'

import classes from './MessageBox.module.css'
import close from '../../../images/Close-dark.svg'
import BackdropBlur from './BackdropBlur'

const MessageBox = (props) => {
    const portalElement = document.getElementById('overlays')

    return (
        <Fragment>
            {ReactDom.createPortal(<BackdropBlur className={classes['message-wrapper']}>
            <div className={`${classes.message} ${props.className}`}>
                <div className={classes['message-header']}>
                <h3 className={classes['message-title']}>{props.title}</h3>
                <img className={classes['message-close']} src={close} onClick={props.onClose} alt=''/>
                </div>
                {props.children}
                </div>
                <div>
                </div>
            </BackdropBlur>, portalElement)}
        </Fragment>
    )
}

export default MessageBox