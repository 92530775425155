import { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useNavigate, useParams} from 'react-router-dom'

import Layout from '../../../components/layout/Layout'
import StatementView from '../../../components/ParentLogin/Statement/Statement'

const Statement = () => {
    const parentLoggedIn = useSelector((state) => state.auth.parentLoggedIn)
    const params = useParams()
    const navigate = useNavigate()

    useEffect(()=>{
        if(!parentLoggedIn){
            navigate(`/${params.organization}/${params.school}/parents/login`, { replace: true })
        }
    },[navigate, parentLoggedIn, params.organization, params.school])


    return (
        <Fragment>{parentLoggedIn ? <Fragment>
            <Layout>
            <StatementView />
            </Layout>
            <Outlet/></Fragment> : null}
        </Fragment>
            )
}

export default Statement