
import TextButton from './TextButton'
import classes from './TextButton.module.css'

const PinkTextButton = (props) => {
    return (
        <TextButton className={`${classes.pink} ${props.className}`} onClick={props.onClick}>{props.children}</TextButton>
    )
}

export default PinkTextButton