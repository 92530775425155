import addCommas from "./addCommasToNumber"

const  toDecimal = (number) => {
    let n = parseFloat(number).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    return n
}

export const toDecimalWithCommas = (number) => {
    let n = toDecimal(number)
    n = addCommas(n)
    return n
}

export default toDecimal