import { useSelector } from 'react-redux'
import classes from './MobileSidebar.module.css'
import SidebarNav from './SidebarNav'
import SidebarNavParents from './SidebarNavParents'

const MobileSidebar = (props) => {
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
    const parentLoggedIn = useSelector((state) => state.auth.parentLoggedIn)
    
    return (
        <div className={classes["main-sidebar"]}>
            <ul className={classes["main-sidebar__items"]}>
            {isLoggedIn && <SidebarNav onClose={props.onClose}/>}
            {parentLoggedIn && <SidebarNavParents onClose={props.onClose}/>}
            </ul>
        </div>
    )
}

export default MobileSidebar