
import classes from './ChangesMessageBox.module.css'
import ButtonL from '../Buttons/Buttons/ButtonL'
import ButtonLOut from '../Buttons/Buttons-Outline/ButtonLOut'
import Close from '../../../images/Close-dark.svg'
import TextButton from '../Buttons/TextButtons/TextButton'

const ChangesMessageBox = (props) => {


    return <div className={classes['message-box-wrapper']}>
        <div className={classes['message-box']}>
        <div className={classes['message-close']} onClick={props.onCancel}><img src={Close} alt=''/></div>
        <h1>Do you want to<br/>save changes?</h1>
        <div className={classes.buttons}>
                <TextButton className='button-primary' onClick={props.onNoSave}>Don't Save</TextButton>
                <ButtonLOut type='button' className='button-primary' onClick={props.onCancel}>Cancel</ButtonLOut>
            <ButtonL type='button' className='button-primary' onClick={props.onSave}>Save Changes</ButtonL>
            </div>
        </div></div>
}

export default ChangesMessageBox