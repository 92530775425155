
import classes from './BackdropBlur.module.css'

const BackdropBlur = (props) => {

    return (
        <div className={`${classes.backdrop} ${props.className}`}>{props.children}</div>
    )
}

export default BackdropBlur