import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    shuls: [],
    isLoading: false,
}

const shulsSlice = createSlice({
    name: "shuls",
    initialState,
    reducers: {
        loadShuls(state, action) {
            state.shuls = action.payload.shuls;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        resetState:() => initialState,
    },
});

export const shulsActions = shulsSlice.actions;

export default shulsSlice;
