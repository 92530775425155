import { Fragment, useEffect, useRef, useState } from 'react'

import classes from './MainHeader.module.css'
import UserDetails from './UserDetails'
import logo from '../../images/Logo-mobile-header.svg'
import hamburger from '../../images/Menu.svg'
import MobileSidebar from './Sidebar/MobileSidebar'
import useOutsideAlerter from '../../hooks/use-outside-alerter'

const MainHeader = (props) => {

  const userRef = useRef()
  const {clickedOutside, resetState} = useOutsideAlerter(userRef)
  const [showUser, setShowUser] = useState()
  const [showMenuBar, setShowMenuBar] = useState()

  useEffect(()=>{
    if(clickedOutside){
      setShowUser(false)
      resetState()
    }
  },[clickedOutside])

const toggleUserShowHandler = () => {
setShowUser(prevState => !prevState)
}

    return (
      <Fragment>
        <header className={classes["top-menu"]}>
        <p className={classes["top-menu__menu"]}>{props.title}</p>
        <img src={hamburger} className={classes['top-menu__hemburger']} onClick={() => setShowMenuBar(prevState => !prevState)} alt=''/>
        <img src={logo} className={classes['top-menu__logo']} alt=''/>
        <div className={classes["top-menu__icons"]}>
          {/*<div className={classes['top-menu__icon']}><img src={Search} alt='Search'/></div>*/}
          {/*<div className={classes['top-menu__icon']}><img src={Notification} alt='Notification'/></div>*/}
          <div className={classes["top-menu__icons--username"]} ref={userRef}>
          <div onClick={toggleUserShowHandler} className={classes['top-menu__username']}>{Array.from(localStorage.getItem('sessionUserName') ? localStorage.getItem('sessionUserName') : [])[0]}
          {showUser && <UserDetails/>}</div></div>
        </div>
      </header>
      {showMenuBar && <MobileSidebar onClose={() => setShowMenuBar(false)}/>}
      </Fragment>
    )
}
export default MainHeader