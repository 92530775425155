
import classes from './ModelTitleClose.module.css'
import Close from '../../../images/Close-light.svg'
import CloseB from '../../../images/Close-dark.svg'

const ModelTitleClose = (props) => {
    return ( 
        <div className={classes['modal-title']}>
            <h2 className={classes['modal-title__main']}>{props.title}</h2>
            <div className={classes['modal-title__close']}>
                <p>Close</p>
                <img src={Close} onClick={props.onClose} alt=''/>
            </div>
            <div className={classes['mobile-close']}>
                <img src={CloseB} onClick={props.onClose} alt=''/>
            </div>
           </div>
    )
}

export default ModelTitleClose