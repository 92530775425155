import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    students: [],
    loadedStudents: false,
    selectedStudent: {},
    isLoading: false,
}

const studentsSlice = createSlice({
    name: "students",
    initialState,
    reducers: {
        loadStudents(state, action) {
            state.loadedStudents = true
            state.students = action.payload.students;
        },
        setSelectedStudent(state, action){
            var selected = state.students.find(s => s.StudentID === action.payload)
            if(selected){
                state.selectedStudent = selected
            }
            else if(typeof action.payload === 'object'){
                state.selectedStudent = action.payload
            }
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        resetStudentsInfo(state) {
            state.students = []
        },
        resetSelectedStudent(state){
            state.selectedStudent = {}
        },
        resetState:() => initialState,
    },
});

export const studentsActions = studentsSlice.actions;

export default studentsSlice;
