import { Fragment, useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


import classes from './LoginApplication.module.css'
import { authActions } from '../../store/auth-slice';
import useResetState from '../../hooks/use-reset';
import LoadingSpinnerMiddle from '../UI/LoadingSpinners/LoadingSpinnerMiddle';
import SmallPopup from '../UI/MessageBoxs/SmallPopup';
import BackdropBlur from '../UI/Modals/BackdropBlur';

const LoginApplication = () => {

    const companyNameRef = useRef()
    const usernameRef = useRef()
    const passwordRef = useRef()

    //#region hooks
    const dispatch = useDispatch();
    const history = useNavigate();
    const { resetState } = useResetState()
  
    const url = useSelector((state) => state.auth.url)
  
    const [isLoading, setIsLoading] = useState()
    const [message, setMessage] = useState('')
    


    //#endregion
  
    //#region log in
    const loginHandler = (event) => {
      event.preventDefault()
      setMessage(null)
    
      if(companyNameRef.current.value.trim().length === 0){
        console.log('Company Name is Required!')
        return;
      }
      if(usernameRef.current.value.trim().length === 0){
        console.log('User Name is Required!')
        return;
      }
      if(passwordRef.current.value.trim().length === 0){
        console.log('Password is Required!')
        return;
      }
      const loginData = { 'companyname': companyNameRef.current.value, 'username': usernameRef.current.value, 'password': passwordRef.current.value, 'grant_type': 'password' }
  
      var formBody = [];
      for (var property in loginData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(loginData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
  
      setIsLoading(true)
      try {
        login(formBody)
      }
      catch (error) {
        setMessage('error')
        setIsLoading(false)
      }
     
    }
  
    async function login(loginData) {
      //resetState()
      try {
        const response = await fetch(url + 'token', {
          method: 'POST',
          body: loginData,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
          }
        })
        const data = await response.json()
        if (response.ok) {
          if (data.access_token) {
            if(data.role === 'user' || data.role === 'hubUser'){
                dispatch(authActions.login({ role: data.role, token: data.access_token, duration: data.expires_in, userName: usernameRef.current.value, addPermission: data.addPermission, 
                  updatePermission: data.updatePermission, deletePermission: data.deletePermission, 
                  hub: data.hub, allowChargeCreditCard: data.addPermission, companyName: companyNameRef.current.value, inTrial: data.inTrial, 
                  trialEndDate: data.trialEndDate, hasJiraCredentials: data.hasJiraCredentials }))
                if(data.role === 'hubUser' || (data.role === 'user' && data.hub === 'True')){
                  history('/hub-app')
                }
                else{
                  setMessage('Access to work hub is denied, please contact administrator')
                }
              }

          }
        }
        else {
          let errorMessage = 'Authentication failed!';
          if (data && data.error_description) {
            errorMessage = data.error_description;
          }
          setMessage(errorMessage);
        }
  
      } catch (error) {
       setMessage(error)
      }
      setIsLoading(false)

    }  
    //#endregion

    return (
        <Fragment>
          
            <input ref={companyNameRef} id='companyName' autoFocus className={classes.input}/>
            <input ref={usernameRef} id='userName' className={classes.input}/>
            <input ref={passwordRef} id='password' className={classes.input}/>
            <button onClick={loginHandler} id='login' className={classes.button}>Login</button>
            {!message && <div className={classes['spinner-wrapper']}><LoadingSpinnerMiddle/></div>}
            
            {message && <BackdropBlur className={classes.backdrop}><SmallPopup className={classes.popup}>
              <h2>{message}</h2>
              </SmallPopup>
              </BackdropBlur>}

        </Fragment>
    )
}

export default LoginApplication