import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    solicitors: [],
    isLoading: false,
}

const solicitorsSlice = createSlice({
    name: "solicitors",
    initialState,
    reducers: {
        loadSolicitors(state, action) {
            state.solicitors = action.payload.solicitors;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        resetState:() => initialState,
    },
});

export const solicitorsActions = solicitorsSlice.actions;

export default solicitorsSlice;
