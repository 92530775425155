import { useState, useEffect, useRef } from 'react'

import classes from './CustomSelectFreeText.module.css'

const CustomSelectFreeText = (props) => {
    const [items, setItems] = useState(props.options)
    const [inputValue, setInputValue] = useState(props.selectedValue)
    const [isOptionsOpen, setIsOptionsOpen] = useState(false)
    const [cursor, setcursor] = useState(0)

    const itemRef = useRef()

    useEffect(()=> {
        setInputValue(props.selectedValue)
        if(props.selectedValue && props.selectedValue !== ''){
            var filteredList = props.options.filter(item => item.name.toString().toLowerCase().includes(props.selectedValue.toString().toLowerCase()))
            setItems(filteredList)
            if(filteredList.length === 0){
                setIsOptionsOpen(false)
                setcursor(0)
            }
        }
        else{
            setItems(props.options)
        }

    },[props.selectedValue, props.options])


    const changeInputHandler = (e) => {
        props.onChange(e.target.value)
        /*setInputValue(e.target.value)
        var filteredList = props.list
        if(e.target.value !== ''){
         filteredList = props.list.filter(item => item.label.toLowerCase().includes(e.target.value.toLowerCase()))
        }
        setItems(filteredList)
        if(items.length === 0 || (items.length === 1 && items[0].label === e.target.value)){
            setIsOptionsOpen(false)
            setcursor(0)
        }*/
        setIsOptionsOpen(true)
    }

    const focusInputHandler = () => {
        if(isOptionsOpen){
            setcursor(0)
        }
        setIsOptionsOpen(preState => !preState)
    }

    const optionChanged = (index) => {
        const value = items[index].name
        props.onChange(value)
        setInputValue(value)
    }
    const setSelectedThenCloseDropdown = (index) => {
        optionChanged(index)
        setIsOptionsOpen(false);
        setcursor(0)
      };
    
      const inputKeyDownHandler = (e) => {
        if (e.keyCode === 38 && cursor > 0) {
          setcursor(prevState => prevState - 1)
        }
        else if (e.keyCode === 40 && cursor < items.length - 1) {
          setcursor(prevState => prevState + 1)
    
        }
        else if (e.key === 'Enter') {
          setSelectedThenCloseDropdown(cursor)
        }
      }

      useEffect(() => {
        var itemComponent = itemRef;
        if (itemComponent && itemComponent.current) {
          itemComponent.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        }
      }, [cursor])
    
    
      const handleKeyDown = (index) => (e) => {
        switch (e.key) {
          case " ":
          case "SpaceBar":
          case "Enter":
            e.preventDefault();
            setSelectedThenCloseDropdown(index);
            break;
          default:
            break;
        }
      };
        
      const unFocuseHanler = (event) => {
        if(event.relatedTarget && event.relatedTarget.id  && event.relatedTarget.id === "listItem"){
            return
        }
        setIsOptionsOpen(false)
        setcursor(0)
      }
    

    return (
        <div className={`${classes.search} ${props.invalid && classes['invalid-input']} ${props.className}`} onBlur={unFocuseHanler}>
            <label className={classes['select-label']}>{props.label}{props.required && <span className={classes['required-input']}>*</span>}</label>
            <input className={classes['select-input']} type="text" onChange={changeInputHandler} value={inputValue}  onClick={focusInputHandler} onKeyDown={inputKeyDownHandler} onBlur={() => props.onBlur ? props.onBlur() : null}/>
            {isOptionsOpen && <ul
          className={classes.options} 
          role="listbox"
          tabIndex={-1}>
          {items.map((option, index) => (
            <li
            className={option.name === inputValue ? classes['selected-item'] : cursor === index ? classes['hover-item'] : ''}
              key={option.value}
              ref={cursor === index ? itemRef : null}
              id='listItem'
              tabIndex={0}
              onKeyDown={handleKeyDown(index)}
              onClick={() => {
                setSelectedThenCloseDropdown(index);
              }}
            >
              {option.name}
            </li>
          ))}
        </ul>}
        </div>

    )
}

export default CustomSelectFreeText