
import classes from './Button.module.css'
import LoadingSpinnerButton from '../../LoadingSpinners/LoadingSpinnerButton'


const Button = (props) => {
    return (
        <button className={`${classes['btn']} ${props.delete && classes.delete} ${props.className}`} type={props.type ? props.type : 'button'} onClick={() => {!props.loading && props.onClick()}} disabled={props.disabled}>
            {props.children}
            {props.loading && <LoadingSpinnerButton/>}
            </button>
    )
}

export default Button