
const formatAddress = (address) => {
    var formattedAddress = ''
    if(address.Address && address.Address.trim() !== ''){
        formattedAddress = address.Address + ', '
    }
    if(address.City && address.City.trim() !== ''){
        formattedAddress = formattedAddress + address.City + ', '
    }
    if(address.State && address.State.trim() !== ''){
        formattedAddress = formattedAddress + address.State + ' '
    }
    if(address.Zip && address.Zip.trim() !== ''){
        formattedAddress = formattedAddress + address.Zip
    }
    return formattedAddress
}

export default formatAddress