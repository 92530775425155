import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    defaultAmounts: null,
}

const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        setDefaultAmounts(state, action) {
            const da = action.payload.find(a => a.Name === 'Default Amounts')
            if(da){
                state.defaultAmounts = da.Value;
            }
        },
        resetState:() => initialState,
    },
});

export const settingsActions = settingsSlice.actions;

export default settingsSlice;
