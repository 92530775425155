
import classes from './Popup.module.css'

const Popup = (props) => {

    return (<div className={`${classes.popup} ${props.className}`} onClick={props.onClick}>
    {props.children}
  </div>)
}

export default Popup