import { Fragment } from 'react'
import ReactDom from 'react-dom'

import classes from './ModalNew.module.css'
import Close from '../../../images/Close-light.svg'
import CloseB from '../../../images/Close-dark.svg'
import BackdropBlur from './BackdropBlur'
import ModelTitleClose from '../Others/ModelTitleClose'

const ModalNew = (props) => {

    const portalElement = document.getElementById('overlays')

    return(
    <Fragment>
        {ReactDom.createPortal(<BackdropBlur className={props.backdropClassName}>
            <div className={`${classes.modal} ${props.modelClassName}`}>
            <ModelTitleClose title={`${props.notNew ? '' : 'New'} ${props.title}`} onClose={props.onClose}/>
        <div className={`${classes['modal-white']} ${props.className}`}>
            <form>{props.children}</form>
            </div>
            </div>
            </BackdropBlur>, portalElement)}
    </Fragment>)
}

export default ModalNew

