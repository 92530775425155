import { useSelector, useDispatch } from "react-redux/es/exports";

import MessageBox from "../Modals/MessageBox";
import classes from './ErrorMessage.module.css'
import { uiActions } from "../../../store/ui-slice";
import errorCheck from '../../../images/error-check.svg'


const ErrorMessageBox = () => {
    const dispatch = useDispatch()

    const errorMessage = useSelector((state) => state.ui.errorMessage)

    const message = errorMessage.message === 'Failed to fetch' ? "Connection Failed!" : errorMessage.message

    const closeMessageHandler = () => {
        dispatch(uiActions.resetErrorMessage())
    }
    return (
        <MessageBox onClose={closeMessageHandler}>
            <img src={errorCheck} alt=''/>
            {errorMessage.title && <h1>{errorMessage.title}</h1>}
            <h1 className={classes.message}>{message}{errorMessage.link && <a href={errorMessage.link} target="_blank" rel='noreferrer noopener'>{errorMessage.link}</a>}</h1>
        </MessageBox>
    )
}

export default ErrorMessageBox