import { Fragment, useEffect, useState } from 'react'
import classes from './NumbersPopup.module.css'
import { useSelector } from 'react-redux'

const NumbersPopup = (props) => {
  const dashboards = useSelector((state) => state.hub.dashboards)
  const loadedAllDashboards = useSelector((state) => state.hub.loadedAllDashboards)

  const [numbers, setNumbers] = useState([])
  const [showTemplates, setShowTemplates] = useState()
  const [templates, setTemplates] = useState([])
  const [showSendEdit, setShowSendEdit] = useState()

  useEffect(() => {
    let numbers = []
    let nums
    if(props.phoneMobile){
      nums = <Fragment><p className={classes.name} onClick={() => clickNumberHandler('PhoneMobile', props.phoneMobile)}>Father</p>
                  <p className={classes.number} onClick={() => clickNumberHandler('PhoneMobile', props.phoneMobile)}>{props.phoneMobile}</p>
                  {showTemplates === 'PhoneMobile' && <div className={classes['templates-popup']} id={`${props.dashboardSettingId} ${props.id} PhoneMobile ${props.phoneMobile.replace(/[^0-9]/g,"")}`}>{templates}</div>}
              </Fragment>
      numbers = [...numbers, nums]
    }
    if(props.phoneSpouse){
      nums = <Fragment><p className={classes.name} onClick={() => clickNumberHandler('PhoneSpouse', props.phoneSpouse)}>Mother</p>
                  <p className={classes.number} onClick={() => clickNumberHandler('PhoneSpouse', props.phoneSpouse)}>{props.phoneSpouse}</p>
                  {showTemplates === 'PhoneSpouse' && <div className={classes['templates-popup']} id={`${props.dashboardSettingId} ${props.id} PhoneSpouse ${props.phoneSpouse.replace(/[^0-9]/g,"")}`}>{templates}</div>}
              </Fragment>
      numbers = [...numbers, nums]
    }
    if(props.email){
      nums = <Fragment><p className={classes.name} onClick={() => clickNumberHandler('Email', props.email)}>Email</p>
                  <p className={classes.number} onClick={() => clickNumberHandler('Email', props.email)}>{props.email}</p>
                  {showTemplates === 'Email' && <div className={classes['templates-popup']} id={`${props.dashboardSettingId} ${props.id} Email ${props.email}`}>{templates}</div>}
                  </Fragment>
                  numbers = [...numbers, nums]
    }
    if(props.phoneHome){
      nums = <Fragment><p className={classes.name} onClick={() => clickNumberHandler('Home', props.phoneHome)}>Home</p>
                  <p className={classes.number} onClick={() => clickNumberHandler('Home', props.phoneHome)}>{props.phoneHome}</p></Fragment>
      numbers = [...numbers, nums]
    }
  setNumbers(numbers)    
  }, [props, showTemplates, templates])

  useEffect(()=>{
    console.log(loadedAllDashboards)
    if(dashboards.length > 0 && loadedAllDashboards){
      let dashboard = dashboards.find(d=> d.DashboardSettingID === props.dashboardSettingId)
      if(dashboard){
        const tempTemplates = [...dashboard.SmsTemplates, {TemplateID: 0, Name: 'New Template'}]
        let t = tempTemplates.map((template, i) => {
          return <div key={template.TemplateID} className={`${classes['number-item']} ${(i + 1) % 2 === 0 && classes.second} ${template.TemplateID === 0 && classes.hide}`} onClick={() => template.TemplateID > 0 ? selectTemplateHandler(template.TemplateID) : props.onClose()} id={template.TemplateID === 0 ? 'New-Template' : template.TemplateID}>
            <p className={classes.name}>{template.Name}</p>
            {showSendEdit === template.TemplateID && <div className={classes['send-popup']} id='Send-Edit'>
              {/*<div className={classes['number-item']} onClick={sendMessageHandler}><p className={classes.name}>Send</p></div>*/}
              <div className={`${classes['number-item']} ${classes.second} ${classes.hide}`} id='Edit-Template' onClick={props.onClose}><p className={classes.name}>Edit</p></div>
              </div>}
          </div>
        })
        setTemplates(t)
      }
      }
  },[dashboards, showSendEdit, loadedAllDashboards])

  const clickNumberHandler = (type, number) => {
    if(props.message){
      if(type === 'PhoneMobile' || type === 'PhoneSpouse' || type === 'Email'){
        setShowTemplates(type)
      }
    }
  }

  const selectTemplateHandler = (id) => {
    setShowSendEdit(id)
  }

  const sendMessageHandler = () => {

  }
  

    return <Fragment>{numbers.length > 0 && <div className={classes.popup}>
      {numbers && numbers.map((n, i) => {
            return <div key={Math.random()} 
                      className={`${classes['number-item']} ${(i + 1) % 2 === 0 && classes.second}`}>
                {n}
            </div>
        })}
    </div>}
    </Fragment>
}

export default NumbersPopup