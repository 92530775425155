
import classes from './AddButton.module.css'
import Plus from '../../../images/Plus.svg'
import PlusG from '../../../images/Plus-g.svg'


const AddButton = (props) => {

    return (
        <div className={`${classes['add-button']} ${props.border && classes.border} ${props.className}`} onClick={props.onClick}>
        <img src={props.green ? PlusG : Plus} alt='' className={classes['img']}/>
       <div className={`${classes['btn-text']} ${props.green && classes.green}`}>{props.children}</div>
    </div>

    )
}

export default AddButton