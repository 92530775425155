import { createSlice } from "@reduxjs/toolkit";

const initialState = {
        accounts: [],
        isLoading: false,
   }
const accountsSlice = createSlice({
    name: "accounts",
    initialState,
    reducers: {
        loadAccounts(state, action) {
            state.accounts = action.payload.accounts;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        resetState:() => initialState,
    }});

export const accountsActions = accountsSlice.actions;

export default accountsSlice;
