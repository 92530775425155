import classes from './LoadingSpinnerButtonLight.module.css'

const LoadingSpinnerButtonLight = () => {
    return (
        <div className={classes.container}>
        <span className={classes.loader}></span> 
        </div>  
    )
}

export default LoadingSpinnerButtonLight