import classes from './SidePopup.module.css'
import successImg from '../../../images/message-success-circle.svg'
import erroImg from '../../../images/message-error.svg'

const SidePopup = (props) => {
    return (
        <div className={`${classes.popup} ${props.status === 'success' ? classes.success : classes.error}`}>
            <img alt='' src={props.status === 'success' ? successImg : erroImg}/>
            <div className={classes.messages}>
                <p className={classes.header}>{props.header}</p>
                <p className={classes.message}>{props.message}</p>
            </div>
        </div>
    )
}

export default SidePopup