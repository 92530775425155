import LoadingSpinnerButton from '../../LoadingSpinners/LoadingSpinnerButton'
import classes from './SubmitButton.module.css'

const SubmitButton = (props) => {

    return(
        <button type={props.submit ? 'submit' : 'button'} className={`${props.className} ${classes['login-button']}`} onClick={props.onClick}>{props.children}{props.loading && <LoadingSpinnerButton/>}</button>
    )
}

export default SubmitButton


