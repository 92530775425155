
import classes from './ButtonLOut.module.css'
import ButtonOut from './ButtonOut'

const ButtonLOut = (props) => {
    return (
        <ButtonOut className={`${classes['btn-l']}  ${props.className}`} type={props.type ? props.type : 'button'} onClick={props.onClick} disabled={props.disabled}>{props.children}</ButtonOut>
    )
}

export default ButtonLOut