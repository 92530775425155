import classes from './SmallPopup.module.css'
import Close from '../../../images/Close-dark.svg'
import { Fragment } from 'react'
import ReactDom from 'react-dom'


const SmallPopup = (props) => {
    const portalElement = document.getElementById('overlays')

    return (<Fragment>
    {ReactDom.createPortal(
<div className={`${classes['email-popup']} ${props.className}`}>
    <img src={Close} className={classes['email-popup__close']} alt='' onClick={props.onClose}/>
    {props.children}
</div>, portalElement)}
</Fragment>)
}

export default SmallPopup