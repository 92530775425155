import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pledges: [],
    loadedPledges: false,
    quantity: 50,
    selectedPledge: {},
    isLoading: false,
    pendingPledge: null,
    pledgesForFilter: [],
    pledgesForDonor: [],
    pledgesForDonorLoading: false,
    pledgesForPayment: [],
    recurringPledges: [],
    loadedRecurringPledges: false,
    recurringQuantity: 50,
    selectedRecurringPledge: {},
    recurringIsLoading: false,
    recurringPledgesForFilter: [],
    recurringPledgesForDonor: [],
    recurringPledgesForPayment: [],
}

const pledgesSlice = createSlice({
    name: "pledges",
    initialState,
    reducers: {
        loadPledges(state, action) {
            state.pledges = action.payload.pledges;
            state.loadedPledges = true;
            state.quantity = action.payload.pledges.length
            if(Object.keys(state.selectedPledge).length !== 0){
                var pledge = action.payload.pledges.find(pledge => pledge.PledgeID === state.selectedPledge.PledgeID)
                if(pledge){
                    state.selectedPledge = pledge
                }
            }
        },
        loadPledgeIds(state, action) {
            state.pledgesForFilter = action.payload.pledges.map(pledge => {
                return {id: pledge, label: pledge.toString()}
            })
        },
        showMoreRows(state) {
            state.quantity = state.quantity + 50
        },
        showLessRows(state) {
            state.pledges = state.pledges.slice(0, state.quantity - 51)
            state.quantity = state.quantity - 50
        },
        loadPledgesForDonor(state, action){
            state.pledgesForDonor = action.payload.pledges
            if(Object.keys(state.selectedPledge).length > 0){
                const pledge = action.payload.pledges.find(p => p.PledgeID === state.selectedPledge.PledgeID)
                if(pledge){
                    state.selectedPledge = pledge
                }
            }
        },
        resetPledgesForDonor(state){
            state.pledgesForDonor = []
        },
        loadPledgesForPayment(state, action){
            state.pledgesForPayment = action.payload.pledges
        },
        resetPledgesForPayment(state){
            state.pledgesForPayment = []
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        setPledgeForDonorLoading(state, action){
            state.pledgesForDonorLoading = action.payload
        },
        setSelectedPledge(state, action) {
            if (action.payload) {
                const id = action.payload
                var selectedPledge = state.pledges.find(pledge => pledge.PledgeID === id)
                if (selectedPledge) {
                    state.selectedPledge = selectedPledge
                }
                else if (typeof action.payload === 'object') {
                    state.selectedPledge = action.payload
                }
                else{
                     selectedPledge = state.pledgesForDonor.find(pledge => pledge.PledgeID === id) 
                     if (selectedPledge) {
                        state.selectedPledge = selectedPledge
                    }
                }
            }
        },
        resetSelectedPledge(state) {
            state.selectedPledge = {}
        },
        setPendingPledge(state, action) {
            state.pendingPledge = action.payload
        },
        resetPendingPledge(state) {
            state.pendingPledge = null
        },
        loadRecurringPledges(state, action){
            state.recurringPledges = action.payload.pledges
            state.loadedRecurringPledges = true
            state.recurringQuantity = action.payload.pledges.length
        },
        loadRecPledgeIds(state, action) {
            state.recurringPledgesForFilter = action.payload.pledges.map(pledge => {
                return {id: pledge, label: pledge.toString()}
            })
        },
        showMoreRecurringRows(state) {
            state.recurringQuantity = state.recurringQuantity + 50
        },
        showLessRecurringRows(state) {
            state.recurringPledges = state.pledges.slice(0, state.recurringQuantity - 51)
            state.recurringQuantity = state.recurringQuantity - 50
        },
        setRecurringIsLoading(state, action) {
            state.recurringIsLoading = action.payload
        },
        setSelectedRecurringPledge(state, action){
            if (action.payload) {
                const id = action.payload
                var selectedPledge = state.recurringPledges.find(pledge => pledge.PledgeRecurringID === id)
                if (selectedPledge) {
                    state.selectedRecurringPledge = selectedPledge
                }
                else if (typeof action.payload === 'object') {
                    state.selectedRecurringPledge = action.payload
                }
                else{
                     selectedPledge = state.recurringPledgesForDonor.find(pledge => pledge.PledgeRecurringID === id) 
                     if (selectedPledge) {
                        state.selectedRecurringPledge = selectedPledge
                    }
                   }
            }
        },
        resetSelectedRecurringPledge(state) {
            state.selectedRecurringPledge = {}
        },

        loadRecurringPledgesForDonor(state, action){
            state.recurringPledgesForDonor = action.payload.pledges
            //if(Object.keys(state.selectedPledge).length > 0){
            //    const pledge = action.payload.pledges.find(p => p.PledgeID === state.selectedPledge.PledgeID)
            //    if(pledge){
            //        state.selectedPledge = pledge
            //    }
            //}
        },
        resetRecurringPledgesForDonor(state){
            state.recurringPledgesForDonor = []
        },
        loadRecurringPledgesForPayment(state, action){
            state.recurringPledgesForPayment = action.payload.pledges
        },
        resetRecurringPledgesForPayment(state){
            state.recurringPledgesForPayment = []
        },
        resetPledgesLists(state){
            state.pledgesForDonor = []
            state.pledgesForPayment = []
            state.recurringPledgesForDonor = []
            state.recurringPledgesForPayment = []
        },

        resetState: () => initialState,
    },
});

export const pledgesActions = pledgesSlice.actions;

export default pledgesSlice;
