import { Fragment } from 'react'
import ReactDom from 'react-dom'
import BackdropBlur from './BackdropBlur'

import classes from './ModalDetails.module.css'
import Arrow from '../../../images/Arrow - Right with Line.svg'
import ArrowW from '../../../images/Arrow - Right with Line-W.svg'
import Close from '../../../images/Close-light.svg'

const ModalDetails = (props) => {
    const portalElement = document.getElementById('overlays')

    return (
        <Fragment>
            {ReactDom.createPortal(<BackdropBlur>
                <div className={classes['modal-back']} onClick={props.onClose}><img src={Arrow} alt='' /></div>
                <div className={classes['mobile-back']} onClick={props.onClose}><img src={ArrowW} alt='' /></div>
                <div className={`${classes.modal}  ${props.className}`}>
                    <div className={classes['modal-title']}>
                        <h2>{props.title} Details</h2>
                        {props.id && <p>{props.title} ID: {props.id}</p>}
                        <img className={classes['mobile-close']} src={Close} onClick={props.onClose} alt=""/>
                    </div>
                    <div className={classes['details-section']}>{props.children}</div>
                </div></BackdropBlur>, portalElement)}
        </Fragment>)

}

export default ModalDetails