import { useState, useRef, useEffect } from 'react'

import classes from './DonorSearchBox.module.css'
import Search from '../../../../images/Search.svg'
import DonorSearchItem from './DonorSearchItem';
import filterObjectArray from '../../../../functions/filterObjectArray';

const DonorSearchBox = (props) => {
  const itemRef = useRef()

  const [items, setItems] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const [cursor, setcursor] = useState(0)

  const changeInputHandler = (e) => {
    setInputValue(e.target.value)
    var filteredList = []
    if (e.target.value !== '') {
      filteredList = filterObjectArray(props.list, e.target.value)
    }
    setItems(filteredList)
    if (items.length === 0 || (items.length === 1 && items[0].label === e.target.value)) {
      setIsOptionsOpen(false)
    }
    setIsOptionsOpen(true)
  }

  const optionChanged = (index) => {
    if (items.length !== 0) {
      const value = items[index].value
      props.onSelect(value)
    }
  }
  const setSelectedThenCloseDropdown = (index) => {
    optionChanged(index)
    setIsOptionsOpen(false);
  };

  const handleKeyDown = (index) => (e) => {
    switch (e.key) {
      case " ":
      case "SpaceBar":
      case "Enter":
        e.preventDefault();
        setSelectedThenCloseDropdown(index);
        break;
      default:
        break;
    }
  };

  const inputKeyDownHandler = (e) => {
    if (e.keyCode === 38 && cursor > 0) {
      setcursor(prevState => prevState - 1)
    }
    else if (e.keyCode === 40 && cursor < items.length - 1) {
      setcursor(prevState => prevState + 1)

    }
    else if (e.key === 'Enter') {
      setSelectedThenCloseDropdown(cursor)
    }
  }

  useEffect(() => {
    var itemComponent = itemRef;
    if (itemComponent && itemComponent.current) {
      itemComponent.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }
  }, [cursor])



  const unFocuseHandler = (event) => {
    if (event.relatedTarget && event.relatedTarget.id && event.relatedTarget.id === "listItem") {
      return
    }
    //setIsOptionsOpen(false)
  }


  return (
    <div className={`${classes.search} ${props.className}`} onBlur={unFocuseHandler}>
      <img src={Search} alt='' />
      <input type="text" autoFocus={props.autoFocus} placeholder={props.placeholder} onChange={changeInputHandler} value={inputValue} onFocus={changeInputHandler} onKeyDown={inputKeyDownHandler} />
      {items.length > 0 && isOptionsOpen && <p className={classes['donors__results']}>{items.length} Results</p>}
      {items.length > 0 && isOptionsOpen && <ul
        className={classes.options}
        role="listbox"
        tabIndex={-1}
      >
        {items.map((option, index) => (
          <DonorSearchItem
            key={option.value}
            ref={cursor === index ? itemRef : null}
            onKeyDown={handleKeyDown(index)}
            onClick={() => {
              setSelectedThenCloseDropdown(index);
            }}
            active={cursor === index}
            option={option}
            scrollIntoView
          />
        ))}
      </ul>}
    </div>

  )
}

export default DonorSearchBox