
import Button from './Button'
import classes from './ButtonM.module.css'

const ButtonM = (props) => {
    return (
        <Button className={`${classes['btn-m']}  ${props.className}`} 
        type={props.type ? props.type : 'button'} 
        onClick={props.onClick} 
        disabled={props.disabled} 
        loading={props.loading}
        delete={props.delete}>
            {props.children}
            </Button>
    )
}

export default ButtonM