import { useSelector } from 'react-redux'

import classes from './MainSidebar.module.css'
import logo from '../../../images/Logo-sidebar.svg'
import SidebarNav from './SidebarNav'
import SidebarNavParents from './SidebarNavParents'


const MainSideBar = () => {
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
    const parentLoggedIn = useSelector((state) => state.auth.parentLoggedIn)


    return (
        <aside className={classes["main-sidebar"]}>
            <div className={classes["main-sidebar__logo"]}><img src={logo} alt='Logo' /></div>
            <ul className={classes["main-sidebar__items"]}>
            {isLoggedIn && <SidebarNav/>}
            {parentLoggedIn && <SidebarNavParents/>}
            </ul>
        </aside>
    )
}

export default MainSideBar