import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import StatementViewItem from "./StatementViewItem"
import classes from './StatementViewItem.module.css'
import SortDesc from "../../../images/Sort - Desc.svg";
import SortAsce from "../../../images/Sort - Acse.svg";
import CheckboxS from "../../UI/Checkboxs/CheckboxS"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { statementsActions } from "../../../store/statements-slice";
import LoadingSpinner from "../../UI/LoadingSpinners/LoadingSpinner";
import { forwardRef } from "react";


const StatementView = forwardRef((props,ref)=>{

  //#region hooks

    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()

    const statementList = useSelector((state) => state.statements.statement)
    const isLoading = useSelector((state) => state.statements.isLoading)

    const [showPendingPayments, setShowPendingPayments] = useState(false)
    const [valueArrow, setValueArrow] = useState(SortAsce);
    const [dateArrow, setDateArrow] = useState(SortAsce);
    const [typeArrow, setTypeArrow] = useState(SortAsce);
    const [detailsArrow, setDetailsArrow] = useState(SortAsce);
    const [studentArrow, setStudentArrow] = useState(SortAsce)
    const [statement, setStatement] = useState([])
    const [statementItems, setStatementItems] = useState([])
    const [balance, setBalance] = useState('')
  
    useEffect(() => {
      if(statementList.length > 0){
        var balance = statementList[0].BalanceForward
        statementList.forEach(s => {
          if(s.PaymentID > 0){
            balance = balance - s.Value
          }
          else if(s.PledgeID > 0){
            balance = balance + s.Value
          }
        })
        dispatch(statementsActions.setBalance(balance))
      }
    },[statementList, dispatch])

    useEffect(()=>{
      if(showPendingPayments){
        if(statementList.length > 0){
          var list = [...statementList].sort((a, b) =>  new Date(a.Date) - new Date(b.Date))
          setStatement(list)
        }
      }
      else{
        list = [...statementList].filter(s => !s.Status  || s.Status !== 'Pending')
        list = list.sort((a, b) =>  new Date(a.Date) - new Date(b.Date))
        setStatement(list)
      }
    },[showPendingPayments, statementList])

    useEffect(()=>{
      if(statement.length > 0){
        var list = [...statement]
        var balance = list[0].BalanceForward
        list = list.map(s => {
        if(s.PaymentID > 0){
          balance = balance - s.Value
        }
        else if(s.PledgeID > 0){
          balance = balance + s.Value
        }
        return <StatementViewItem key={s.PaymentID + s.PledgeID} statement={{...s, Balance: balance}} onSelect={()=>{viewDetailsHandler(s.PaymentID > 0 ? s.PaymentID : s.PledgeID, s.PaymentID > 0)}}/>
       })
       setStatementItems(list)
       setBalance(balance)
      }
      else{
        setStatementItems([])
      }
    },[statement])

    //#endregion

  //#region sort

    const changeAllArrowsToDown = () => {
      setValueArrow(SortAsce);
      setDateArrow(SortAsce);
      setTypeArrow(SortAsce);
      setDetailsArrow(SortAsce);
      setStudentArrow(SortAsce)
    };
  
    const switchArrowDirection = (state) => {
      if (state === SortAsce) {
        return SortDesc;
      }
      return SortAsce;
    };
  
    const sortByColumnHandler = (column) => {
      changeAllArrowsToDown();
      let newList = []
   statement.forEach( (s, index) => {
       newList[index] = {...s}
   })
      switch (column) {
        case "value":
          if(valueArrow === SortDesc){
            newList = newList.sort((a, b) => a.Value - b.Value)
          }
          else{
            newList = newList.sort((a, b) => b.Value - a.Value)
          }
          setValueArrow(switchArrowDirection(valueArrow));
          break;
        case "date":
          if(dateArrow === SortDesc){
            newList = newList.sort((a, b) => new Date(a.Date) - new Date(b.Date))
          }
          else{
            newList = newList.sort((a, b) => new Date(b.Date) - new Date(a.Date))
          }
          setDateArrow(switchArrowDirection(dateArrow));
          break;
        case "type":
          if(typeArrow === SortDesc){
            newList = newList.sort((a, b) => a.PaymentID - b.PaymentID)
          }
          else{
            newList = newList.sort((a, b) => b.PaymentID - a.PaymentID)
          }
          setTypeArrow(switchArrowDirection(typeArrow));
          break;
        case "details":
          if(detailsArrow === SortDesc){
            newList = newList.sort((a,b) => {
              const detailsA = a.PaymentID > 0 ? a.PaymentType : a.SchoolType
              const detailsB = b.PaymentID > 0 ? b.PaymentType : b.SchoolType
              return (detailsA > detailsB) ? 1 : ((detailsB > detailsA) ? -1 : 0)})
          }
          else{
            newList = newList.sort((a,b) => {
              const detailsA = a.PaymentID > 0 ? a.PaymentType : a.SchoolType
              const detailsB = b.PaymentID > 0 ? b.PaymentType : b.SchoolType
              return (detailsA < detailsB) ? 1 : ((detailsB < detailsA) ? -1 : 0)})
          }
          setDetailsArrow(switchArrowDirection(detailsArrow));
          break;
        case "student":
          if(studentArrow === SortDesc){
            newList = newList.sort((a,b) => a.StudentName > b.StudentName ? 1 : b.StudentName > a.StudentName ? -1 : 0)
          }
          else{
            newList = newList.sort((a,b) => a.StudentName < b.StudentName ? 1 : b.StudentName < a.StudentName ? -1 : 0)
          }
          setStudentArrow(switchArrowDirection(studentArrow));
          break;
        default:
          break;
      }
      setStatement(newList)
    };  

    //#endregion

  //#region event handlers

    const viewDetailsHandler = (id, isPayment) => {
        //set up selected payment/charge
        if(isPayment){
          dispatch(statementsActions.setSelectedPayment(id))
          navigate(`/${params.organization}/${params.school}/parents/statement/payment-details`)
        }
        else{
          dispatch(statementsActions.setSelectedPledge(id))
          navigate(`/${params.organization}/${params.school}/parents/statement/charge-details`)
        }
    }
  
    //#endregion

    return(<Fragment>
        <section ref={ref} className={classes["statement-view__wrapper"]}>
               <CheckboxS id='successfulPayments' label='Include your pending payments' onChange={() => setShowPendingPayments(prevState => !prevState)} checked={showPendingPayments} onBlur={()=>{}} className={classes['successful-payments']}/>
        {statementItems && statementItems.length > 0 && 
            <div className={classes["statement-view__header"]}>
            <p className={classes["value"]}>Value<img src={valueArrow} alt='Arrow' onClick={() => sortByColumnHandler('value')} /></p>
            <p className={classes["balance"]}>Running Balance</p>
            <p className={classes["date"]}>Date<img src={dateArrow} alt='Arrow' onClick={() => sortByColumnHandler('date')} /></p>
            <p className={classes["type"]}>Payment/ Charge<img src={typeArrow} alt='Arrow' onClick={() => sortByColumnHandler('type')} /></p>
            <p className={classes["student"]}>Student<img src={studentArrow} alt='Arrow' onClick={() => sortByColumnHandler('student')} /></p>
            <p className={classes["details"]}>Details<img src={detailsArrow} alt='Arrow' onClick={() => sortByColumnHandler('details')} /></p>
        </div>}
        <div className={classes["statement-view__list"]}>
        {statementList.length > 0 && <div className={`${classes['view-item']} ${classes['prev-balance-item']}`}>
            <p className={classes.balance}>${parseFloat(statementList[0].BalanceForward).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            <p className={classes['prev-balance']}>Previous Balance</p>
          </div>}
        {statementItems}
        {statementList.length > 0 && <div className={`${classes['view-item']} ${classes['running-balance-item']}`}>
            <p className={classes.balance}>${parseFloat(balance).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            <p className={classes['prev-balance']}>Due Now</p>
          </div>}
        {(!statementItems || statementItems.length === 0) && !isLoading && <p className="list-no-found">No Items found</p>}
        </div>
        {isLoading && <LoadingSpinner className={classes.spinner}/>}
        </section>
        </Fragment>
    )
})

export default StatementView