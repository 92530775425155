import classes from './LoadingSpinner.module.css'

const LoadingSpinner = (props) => {
    return (
        <div className={`${classes['spinner-wrapper']} ${props.className}`}>
        <span className={classes.loader}></span>   
    </div>)
}

export default LoadingSpinner;
