import { Fragment } from "react"
import TaskListForm from "../../components/Hub/Tasks/TasksList/TaskListForm"
import { Outlet } from "react-router-dom"

const TasksApplication = () => {
    return (
        <Fragment>
            <main style={{padding: '0'}}>
            <TaskListForm/>
            <Outlet/>
            </main>
        </Fragment>
)}

export default TasksApplication