import { useEffect, useState } from 'react'
import classes from './TaskListTotals.module.css'

const TaskListTotals = (props) => {
    const [under1K, setUnder1K] = useState()
    const [over1K, setOver1K] = useState()
    const [over10K, setover10K] = useState()

    useEffect(()=>{
        if(props.balance && props.balance.length > 0){
            let tasks = props.balance.filter(b => b < 1000)
            if(tasks){
                setUnder1K({amount: tasks.length, percent: Math.round(tasks.length / props.balance.length * 100)})
            }
            tasks = props.balance.filter(b => b >= 1000 && b < 10000)
            if(tasks){
                setOver1K({amount: tasks.length, percent: Math.round(tasks.length / props.balance.length * 100)})
            }
            tasks = props.balance.filter(b => b >= 10000)
            if(tasks){
                setover10K({amount: tasks.length, percent: Math.round(tasks.length / props.balance.length * 100)})
            }
        }
    },[props.balance])

    return <div className={classes.container}>
        <div className={classes.totals}>
            {props.totals.map(t => {
                return <div key={Math.random()}>
                    <p className={classes.name}>{t.name}</p>
                    <h3 className={classes.value}>{t.value}</h3>
                </div>
            })}
        </div>
        <div className={classes.bars}>
            {under1K && <div className={classes['bar-total']}>
                <div className={classes['total-numbers']}>
                    <p className={classes.amount}>{under1K.amount}</p>
                    <p className={classes.compression}>{'<'}$1k</p>
                </div>
                <p className={classes.bar} style={{backgroundImage: `linear-gradient(to right, #e99d42,  #e99d42 ${under1K.percent}% ,#FFFFFF ${under1K.percent}%, #FFFFFF)`}}></p>
            </div>}
            {over1K && <div className={classes['bar-total']}>
            <div className={classes['total-numbers']}>
                <p className={classes.amount}>{over1K.amount}</p>
                <p className={classes.compression}>{'>'}$1k</p>
            </div>
                <p className={classes.bar} style={{background: `linear-gradient(to right, #D72F19,  #D72F19 ${over1K.percent}% ,#FFFFFF ${over1K.percent}%, #FFFFFF)`}}></p>
            </div>}
            {over10K && <div className={classes['bar-total']}>
            <div className={classes['total-numbers']}>
                <p className={classes.amount}>{over10K.amount}</p>
                <p className={classes.compression}>{'>'}$10k</p>
                </div>
                <p className={classes.bar} style={{background: `linear-gradient(to right, #57A2FA,  #57A2FA ${over10K.percent}% ,#FFFFFF ${over10K.percent}%, #FFFFFF)`}}></p>
            </div>}
        </div>
    </div>
}

export default TaskListTotals