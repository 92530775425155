import { paymentTypesActions } from './paymentTypes-slice'
import { uiActions } from './ui-slice';
import store from './index'

export const fetchPaymentTypesList = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(paymentTypesActions.setIsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + 'api/paymenttypes',{
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                throw new Error('Could not fetch payment types list.')
            }
            const data = await response.json()
            return data;
        };

        try {
            const paymentTypesData = await fetchData()
            dispatch(paymentTypesActions.loadPaymentTypes({ paymentTypes: paymentTypesData || [] }))
        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
        finally {
            dispatch(paymentTypesActions.setIsLoading(false))

        }
    };
};


export const setPaymentTypesIsLoading = (value) => {
    return (dispatch) => {
        dispatch(paymentTypesActions.setIsLoading(value))
    }
}



