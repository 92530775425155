import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { authActions } from "../../store/auth-slice"

import Input from "../UI/Inputs/Input"
import LoginModal from "../UI/Modals/LoginModal"
import MessageBox from "../UI/Modals/MessageBox"
import classes from './LoginForm.module.css'

const PortalUserSignUp = () => {

    //#region hooks

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const dispatch = useDispatch()

    const [companyName, setCompanyName] = useState('')
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [userNameInvalid, setUserNameInvalid] = useState()
    const [passwordInvalid, setPasswordInvalid] = useState()
    const [error, setError] = useState()
    const [isLoading, setIsLoading] = useState()
    const [sendEmailLoading, setSendEmailLoading] = useState()
    const [showForgotPassword,  setShowForgotPassword] = useState()

    const url = useSelector((state) => state.auth.url)

    useEffect(()=> {
        if(searchParams.get('company')){
            setCompanyName(searchParams.get('company'))
        }
        if(searchParams.get('email')){
            setUserName(searchParams.get('email'))
        }
    },[])
    //#endregion

    //#region change input handlers 

    const changeCompanyNameHandler = (value) => {
        setCompanyName(value)
    }

    const changeUserNameHandler = (value) => {
        setUserName(value)
        setUserNameInvalid(false)
    }

    const changePasswordHandler = (value) => {
        setPassword(value)
        if(value === confirmPassword){
            setPasswordInvalid(false)
        }
    }

    const changeConfirmPasswordHandler = (value) => {
        setConfirmPassword(value)
        if(value === password){
            setPasswordInvalid(false)
        }
    }

    //#endregion

    //#region sign up

    const signUpHandler = (e) => {
        e.preventDefault()
        if(!userName.includes('@') || !userName.includes('.')){
            setUserNameInvalid(true)
            return
        }
        if(password.trim() !== confirmPassword.trim()){
            setPasswordInvalid(true)
            return;
        }
        if(isLoading){ return; }
        signUp()
    }

    const signUp = async () => {
        setIsLoading(true)
        var token = searchParams.get('token') ? searchParams.get('token') : ''
        var response =  await fetch(`${url}api/portal-user/signup?companyName=${companyName}&userName=${userName}&password=${password}&token=${token}`)
        var data = await response.json()
        if(data === ''){
            // log in
            const loginData = { 'companyname': companyName, 'username': userName, 'password': password, 'grant_type': 'password' }
  
            var formBody = [];
            for (var property in loginData) {
              var encodedKey = encodeURIComponent(property);
              var encodedValue = encodeURIComponent(loginData[property]);
              formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
            try {
                const response = await fetch(url + 'token', {
                  method: 'POST',
                  body: formBody,
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                  }
                })
                const data = await response.json()
                if (response.ok && data.access_token) {
                    dispatch(authActions.portalUserLogin({token: data.access_token, duration: data.expires_in, userName: userName, companyName: companyName, hasJiraCredentials: data.hasJiraCredentials }))
                    navigate('/new-features')
                } 
            }
            catch{

            }
        }
        else{
            if(data.includes('forgot password')){
                const msg = <p>{data.substring(0, data.indexOf('Click') + 6)} <span className={classes['forgot-password']} onClick={forgotPasswordHandler}>Forgot password</span> {data.substring(data.indexOf('password') + 8)}</p>
                setError(msg)
            }
            else{

                setError(data)
            }
        }
        setIsLoading(false)
    }

    //#endregion

    //#region forgot password

    const forgotPasswordHandler = () => {
        if(sendEmailLoading){
            return;
        }
        if(!userName || !userName.includes('@') || !userName.includes('.')){
            setUserNameInvalid(true)
            return;
        }
        sendEmail()
    }
  
    const sendEmail = async () => {
      setSendEmailLoading(true)
      const response = await fetch(`${url}api/send-signup-email-portal-user?email=${userName}&companyName=${companyName}`)
      const data = await response.json()
      if(data === ""){
          setShowForgotPassword(true)
      }
      else{
          setError(data)
      }
      setSendEmailLoading(false)
  }
  
    //#endregion
    const underButton = <Fragment>{error && <p className={classes['error-message']}>{error}</p>}
    <label className={classes['sign-in-label']}>Already have an account? <a href="/login">Log In</a></label></Fragment>

    return (
        <Fragment>
        <LoginModal title='Portal User Sign Up' onSubmit={signUpHandler} isLoading={isLoading} underButton={underButton}>
            <Input placeholder='Company Name' onChange={changeCompanyNameHandler} value={companyName} disabled={isLoading}/>
            <Input autoComplete='new-password' placeholder='Email' onChange={changeUserNameHandler} value={userName} disabled={isLoading} invalid={userNameInvalid}/>
            <Input autoComplete='new-password' placeholder='Password' type='password' onChange={changePasswordHandler} value={password} invalid={passwordInvalid} disabled={isLoading}/>
            <Input placeholder='Confirm Password' type='password' onChange={changeConfirmPasswordHandler} value={confirmPassword} invalid={passwordInvalid} disabled={isLoading}/>
        </LoginModal>
        {showForgotPassword && <MessageBox onClose={() => {setShowForgotPassword(false)}}>
        <label className={classes['forgot-password__message']}>Please reset your password <br/>using the link sent to you in email.</label>
        </MessageBox>}
        </Fragment>
    )
}

export default PortalUserSignUp