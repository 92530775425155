import classes from './Note.module.css'
import check from '../../../../images/check-circle.svg'
import checkG from '../../../../images/check-circle-g.svg'
import TextField from '../../../UI/Inputs/TextField'
import { useState } from 'react'

const Note = (props) => {
    const [checkSrc, setCheckSrc] = useState(check)

    const saveNoteHandler = () => {
        if(props.value && props.value !== ''){
            props.onSave()
        }
    }
   
    return (
        <div className={classes.popup}>
            <div className={classes.header}>
                <p className={classes.title}>Notes</p>
                <img className={classes.check} src={checkSrc} alt='' onClick={saveNoteHandler} onMouseOver={() => setCheckSrc(checkG)} onMouseOut={() => setCheckSrc(check)} />
            </div>
            <TextField id='note' className={classes.note} autoFocus value={props.value} onChange={props.onChange} placeholder='Type note here...'/>
        </div>
    )
}

export default Note