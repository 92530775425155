import { useEffect, useState } from "react";

const useOutsideAlerter = (ref) => {
    const  [clickedOutside, setClickedOutside] = useState(false)

    const resetState = () => {
        setClickedOutside(false)
    }
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setClickedOutside(true)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
    return {
        resetState,
        clickedOutside
    }
  }

  export default useOutsideAlerter